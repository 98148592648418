import { Platform } from '@angular/cdk/platform';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private router = inject(Router);
  private platform = inject(Platform);


  public history: string[] = [];
  private recording = false;
  private isBrowser = false;

  private routerSub: Subscription;

  constructor() {
    this.isBrowser = this.platform.isBrowser;
  }

  public startSaveHistory() {
    if (!this.recording) {
      this.recording = true;
      this.history.push(this.router.url);
      this.routerSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && this.history.at(-1)) {
          // in case of new url we save to history
          if (this.urlWithoutWref(this.history.at(-1)) !== this.urlWithoutWref(event.urlAfterRedirects) ) {
            this.history.push(event.urlAfterRedirects);
          }
          // if url is only an wref update we update history
          if (this.history.at(-1) === this.urlWithoutWref(event.urlAfterRedirects)) {
            this.history[-1] = event.urlAfterRedirects;
          }
        }
      });
    }
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history.pop();

    if (this.history.length > 0) {
      const url = this.history.at(-1);
      this.router.navigateByUrl(url);
    }
  }

  public resetHistory() {
    this.history = [];
    this.recording = false;
    this.routerSub?.unsubscribe();
    this.startSaveHistory();
  }

  private urlWithoutWref(urlString: string) {
    if (!this.isBrowser) {
      return undefined;
    }
    const url = new URL(window.location.origin + urlString);
    url.searchParams.delete('wref');
    return url.toString().replace(window.location.origin, '');
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
  }

}

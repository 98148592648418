import { Component, Input, inject } from '@angular/core';
import { AppService, ColorService } from '../../../services';
import { LetDirective } from '../../directives/let.directive';
import { RouterLink } from '@angular/router';
import { NgClass, AsyncPipe } from '@angular/common';
import { SafePipe } from '../../../pipes/safe.pipe';

@Component({
  selector: 'app-reelax-tickets-logo',
  templateUrl: './reelax-tickets-logo.component.html',
  styleUrls: ['./reelax-tickets-logo.component.scss'],
  standalone: true,
  imports: [
    LetDirective,
    RouterLink,
    NgClass,
    AsyncPipe,
    SafePipe,
  ],
})
export class ReelaxTicketsLogoComponent {
  colorService = inject(ColorService);
  appService = inject(AppService);

  @Input() public isBurger = false;

}

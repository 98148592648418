// eslint-disable-next-line max-classes-per-file
import { Bucket, Currencies, Language, Organizer, PhoneModel, UserBankingInfo, WaitListPurchase } from './';

export type UserType = 'seller' | 'buyer';

export enum Role {
  Admin = 'admin',
  Orga = 'organizer',
  User = 'user',
  Anon = 'anonymous',
  Partner = 'partner'
}

export const roleInfos = {
  [Role.Admin]: {
    key : 'admin',
    name : 'administrateur',
  },
  [Role.Orga]: {
    key : 'organizer',
    name : 'organisateur',
  },
  [Role.User]: {
    key : 'user',
    name : 'utilisateur',
  },
  [Role.Anon]: {
    key : 'anonymous',
    name : 'anonyme',
  },
  [Role.Partner]: {
    key: 'partner',
    name: 'partenaire',
  },
};

export class MangoWallet {
  public id!: number;
  public userInfoId: number;
  public mangoPayWalletId: string;
  public currency: Currencies;

  constructor(data: Partial<MangoWallet>) {
    Object.assign(this, data);
  }
}

export class UserProfile {
  id: number;
  role: Role;
  firstname: string;
  lastname: string;
  email: string;
  verifiedEmail: boolean;
  mobilePhone: PhoneModel;
  birthday: any;
  citizenship: string;
  countryOfResidence: string;
  address: string;
  addressSupplement: string;
  payinFailures: number;
  postCode: string;
  region: string;
  city: string;
  generalConditions: boolean;
  language: Language;
  mangoPayGeneralConditions: boolean;
  mangoPayId: string;
  token: string;
  chargedKyc: boolean;
  isPro: boolean;
  UserAccount: UserAccount;
  UserBankingInfos: UserBankingInfo[];
  Buckets: Bucket[];
  organizerId: number;
  Organizer: Organizer;
  isSeller?: boolean;
  /** @description true is user needs to fill in KYC and IBAN */
  isPayoutSeller?: boolean;
  WaitListPurchases?: WaitListPurchase[];
  MangoWallets: MangoWallet[];
  createdAt: Date;
  updatedAt: Date;

  constructor(options?: Partial<UserProfile>) {
    if (options) {
      this.id = options.id;
      this.role = options.role;
      this.firstname = options.firstname;
      this.lastname = options.lastname;
      this.email = options.email;
      this.mobilePhone = new PhoneModel(options.mobilePhone);
      this.birthday = options.birthday ? new Date(options.birthday) : null;
      this.citizenship = options.citizenship;
      this.countryOfResidence = options.countryOfResidence;
      this.address = options.address;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
      this.addressSupplement = options.addressSupplement;
      this.payinFailures = options.payinFailures;
      this.postCode = options.postCode;
      this.region= options.region;
      this.city = options.city;
      this.generalConditions = options.generalConditions;
      this.language = options.language;
      this.mangoPayGeneralConditions = options.mangoPayGeneralConditions;
      this.mangoPayId = options.mangoPayId;
      this.chargedKyc = options.chargedKyc;
      this.isPro = options.isPro;
      this.token = options.token;
      this.isSeller = options.isSeller;
      this.isPayoutSeller = options.isPayoutSeller;
      if (options.UserAccount) {
        this.verifiedEmail = options.UserAccount?.verifiedEmail;
        this.UserAccount = new UserAccount(options.UserAccount);
      }
      if (options.UserBankingInfos && Array.isArray(options.UserBankingInfos)) {
        this.UserBankingInfos = options.UserBankingInfos?.map( (b) => new UserBankingInfo(b));
      } else if (options.UserBankingInfo) {
        this.UserBankingInfos = [new UserBankingInfo(options.UserBankingInfo)];
      }
      this.Buckets = options.Buckets;
      this.organizerId = options.organizerId;
      this.Organizer = options.Organizer;
      this.WaitListPurchases = options.WaitListPurchases?.map((p) => new WaitListPurchase(p));
      this.MangoWallets = options.MangoWallets?.map((w) => new MangoWallet(w));
    }
  }

  public get UserBankingInfo() {
    return this.UserBankingInfos?.[0];
  }

  public get needAction() {
    return this && this.role !== Role.Anon && (
      !this.verifiedEmail ||
      (this.isSeller && this.isPayoutSeller && !this.UserBankingInfo?.areBankAccountAndKycCompleted)
    );
  }

  /**
   * @deprecated Unused in the code
   */
  public getMangoWallet(currency: Currencies) {
    return this.MangoWallets.find( (w) => w.currency === currency);
  }

  public serialize() {
    const serialized: any = structuredClone(this);
    if (this.mobilePhone) {
      serialized.mobilePhone = this.mobilePhone.e164PhoneNumber;
    }
    return serialized;
  }

  public get domainEmail() {
    return this.email?.split('@')?.[1]?.split('.')?.[0]?.toLowerCase();
  }

}

export class UserAccount {
  id: number;
  verifiedEmail: boolean;
  validationToken: string;
  attempts: number;
  UserProfile?: UserProfile;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);
      if (options.UserInfo) {
        this.UserProfile = new UserProfile(options.UserInfo);
      }
    }
  }
}

export enum UserAccountEmailStatus {
  Old = 'O',
  Pending = 'P',
  New = 'N',
}

export class UserAccountEmail {
  id: number;
  email: string;
  userAccountId: number;
  status: UserAccountEmailStatus;
  createdAt: Date;
  updatedAt: Date;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    }
  }
}


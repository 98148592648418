export const orgaPartners: Array<{imgUrl: string, name: string, website: string}> = [
  {
    imgUrl: 'organizers/live-affair-logo.svg',
    name: 'Live Affair',
    website: 'https://www.liveaffair.com/',
  },
  {
    imgUrl: 'organizers/festival-beauregard-logo.svg',
    name: 'Festival Beauregard',
    website: 'https://www.festivalbeauregard.com/',
  },
  // {
  //   imgUrl: 'organizers/eurockennes-logo.png',
  //   name: 'Les Eurockéennes de Belfort',
  //   website: 'https://www.eurockeennes.fr/'
  // },
  // {
  //   imgUrl: 'organizers/hellfest-logo.png',
  //   name: 'Hellfest',
  //   website: 'https://www.hellfest.fr/',
  // },
  {
    imgUrl: 'organizers/bataclan-logo.svg',
    name: 'Bataclan',
    website: 'https://www.bataclan.fr/',
  },
  {
    imgUrl: 'organizers/rock-en-seine-logo.jpg',
    name: 'Rock en Seine',
    website: 'https://www.rockenseine.com/',
  },
  {
    imgUrl: 'organizers/festival-de-poupet-logo.png',
    name: 'Festival de Poupet',
    website: 'https://www.festival-poupet.com/',
  },
  {
    imgUrl: 'organizers/diogene-productions-logo.png',
    name: 'Diogene Productions',
    website: 'https://www.diogene.fr/',
  },
  {
    imgUrl: 'organizers/cabaret-vert-logo.png',
    name: 'Cabaret Vert',
    website: 'https://cabaretvert.com/',
  },
  {
    imgUrl: 'organizers/no-logo-logo.png',
    name: 'No Logo festival',
    website: 'https://nologofestival.fr',
  },
  {
    imgUrl: 'organizers/furax-logo.png',
    name: 'Furax',
    website: 'https://furax.fr/',
  },
  {
    imgUrl: 'organizers/delta-logo.png',
    name: 'Delta Festival',
    website: 'https://www.delta-festival.com/',
  },
  {
    imgUrl: 'organizers/nuit-de-l-erdre-logo.png',
    name: "La Nuit de l'Erdre",
    website: 'https://www.lanuitdelerdre.fr/',
  },
  {
    imgUrl: 'organizers/regie-scene-logo.jpg',
    name: 'Régie Scène',
    website: 'https://www.regie-scene.com/',
  },
  {
    imgUrl: 'organizers/transmusicales-logo.png',
    name: 'Les Transmusicales',
    website: 'https://www.lestrans.com',
  },
  {
    imgUrl: 'organizers/bleu-citron-logo.svg',
    name: 'Bleu Citron',
    website: 'https://www.bleucitron.net/',
  },
  {
    imgUrl: 'organizers/echo-system-logo.png',
    name: 'Echo System',
    website: 'https://echosystem70.fr/',
  },
  {
    imgUrl: 'organizers/emb-sannois-logo.png',
    name: 'EMB Sannois',
    website: 'https://emb-sannois.org/',
  },
  {
    imgUrl: 'organizers/sakifo-logo.png',
    name: 'Sakifo',
    website: 'https://www.sakifo.com/',
  },
  {
    imgUrl: 'organizers/touquet-music-logo.png',
    name: 'Touquet Music Beach Festival',
    website: 'https://www.touquetmusicbeach.com/',
  },
  {
    imgUrl: 'organizers/trianon-em-logo.png',
    name: 'Le Trianon Elysée Montmartre',
    website: 'https://trianon-elyseemontmartre.com/',
  },
  {
    imgUrl: 'organizers/aeronef-logo.svg',
    name: "L'Aéronef",
    website: 'https://aeronef.fr/',
  },
  {
    imgUrl: 'organizers/roi-arthur-logo.png',
    name: 'Festival du Roi Arthur',
    website: 'https://www.festivalduroiarthur.fr',
  },
  {
    imgUrl: 'organizers/festival-panoramas-logo.png',
    name: 'Festival Panoramas',
    website: 'https://www.festivalpanoramas.com/',
  },
  {
    imgUrl: 'organizers/mama-festival-logo.png',
    name: 'MaMA Festival & Convention',
    website: 'https://www.mamafestival.com/',
  },
  {
    imgUrl: 'organizers/musilac-logo.jpg',
    name: 'Musilac',
    website: 'https://www.musilac.com/',
  },
  {
    imgUrl: 'organizers/vandbfest-logo.svg',
    name: 'V&B Fest',
    website: 'https://vandbfest.fr/',
  },
  {
    imgUrl: 'organizers/file7-logo.png',
    name: 'File7',
    website: 'https://file7.com/',
  },
  {
    imgUrl: 'organizers/megascene-logo.png',
    name: 'Megascène',
    website: 'https://www.megascene.org/',
  },
  {
    imgUrl: 'organizers/nantua-fest-logo.png',
    name: 'Nantua Fest',
    website: 'https://www.nantuafest.fr/',
  },
  {
    imgUrl: 'organizers/tour-met-les-watts-logo.png',
    name: 'La tour met les watts',
    website: 'https://latourmetleswatts.com/',
  },
  {
    imgUrl: 'organizers/vip-logo.png',
    name: 'Le VIP',
    website: 'https://www.levip-saintnazaire.com/',
  },
  {
    imgUrl: 'organizers/temps-machine-logo.png',
    name: 'Le temps machine',
    website: 'https://www.letempsmachine.com/',
  },
  {
    imgUrl: 'organizers/la-manufacture-logo.png',
    name: 'La manufacture',
    website: 'https://www.saint-quentin.fr/1674-la-manufacture.htm',
  },
  {
    imgUrl: 'organizers/murmure-du-son-logo.png',
    name: 'Le murmure du son festival',
    website: 'https://www.murmureduson.org/',
  },
  {
    imgUrl: 'organizers/marche-gare-logo.png',
    name: 'Marché gare',
    website: 'https://marchegare.fr/',
  },
  {
    imgUrl: 'organizers/django-logo.png',
    name: 'Espace Django',
    website: 'https://www.espacedjango.eu/',
  },
  {
    imgUrl: 'organizers/106-logo.png',
    name: 'Le 106',
    website: 'https://www.le106.com/',
  },
  {
    imgUrl: 'organizers/big-car-show-logo.png',
    name: 'The Big Car Show',
    website: 'https://thebigcarshow.com/',
  },
  {
    imgUrl: 'organizers/balelec-logo.png',
    name: 'Balélec',
    website: 'https://balelec.ch/',
  },
  {
    imgUrl: 'organizers/chabada-logo.png',
    name: 'Le Chabada',
    website: 'https://lechabada.com/',
  },
  {
    imgUrl: 'organizers/erva-logo.png',
    name: 'ERVA Festival',
    website: 'https://www.ervafestival.com/',
  },
  {
    imgUrl: 'organizers/europavox-logo.png',
    name: 'Europavox festival',
    website: 'https://www.europavoxfestivals.com/',
  },
  {
    imgUrl: 'organizers/fav-logo.png',
    name: 'Foire aux vins d\'Alsace',
    website: 'https://www.foire-colmar.com/fr/',
  },
  {
    imgUrl: 'organizers/francofolies-reunion-logo.png',
    name: 'Les Francofolies de la Réunion',
    website: 'https://francofolies.re/',
  },
  {
    imgUrl: 'organizers/nuits-secretes-logo.png',
    name: 'Les nuits secrètes',
    website: 'https://lesnuitssecretes.com/',
  },
  {
    imgUrl: 'organizers/pdp-logo.jpg',
    name: 'Printemps de Pérouges',
    website: 'https://www.festival-perouges.org/',
  },
  {
    imgUrl: 'organizers/rocher-palmer-logo.png',
    name: 'Le rocher de Palmer',
    website: 'https://lerocherdepalmer.fr/',
  },
  {
    imgUrl: 'organizers/macki-festival-logo.svg',
    name: 'Macki Festival',
    website: 'https://www.mackimusicfestival.fr/',
  },
  {
    imgUrl: 'organizers/fete-du-bruit-logo.png',
    name: 'Fête du Bruit',
    website: 'https://festival-fetedubruit.com/',
  },
  {
    imgUrl: 'organizers/le-grand-mix-logo.svg',
    name: 'Le Grand Mix',
    website: 'https://legrandmix.com/',
  },
  {
    imgUrl: 'organizers/mediatone-logo.png',
    name: 'Mediatone',
    website: 'https://www.mediatone-lyon.net/',
  },
  {
    imgUrl: 'organizers/rock-n-solex-logo.png',
    name: 'Rock\'n Solex',
    website: 'https://rocknsolex.fr',
  },
  {
    imgUrl: 'organizers/no-logo-bzh-logo.png',
    name: 'No Logo BZH',
    website: 'https://www.nologobzh.com/',
  },
  {
    imgUrl: 'organizers/meuh-folle-logo.png',
    name: 'Festival de la Meuh Folle',
    website: 'https://www.meuhfolle.com/',
  },
  {
    imgUrl: 'organizers/aluna-festival-logo.svg',
    name: 'Aluna Festival',
    website: 'https://aluna-festival.fr/',
  },
  {
    imgUrl: 'organizers/le-mem-logo.png',
    name: 'Le MeM',
    website: 'https://www.lemem.fr/',
  },
  {
    imgUrl: 'organizers/bout-du-monde-logo.png',
    name: 'Festival du Bout du Monde',
    website: 'https://www.festivalduboutdumonde.com/',
  },
  {
    imgUrl: 'organizers/brise-glace-logo.svg',
    name: 'Brise Glace',
    website: 'https://www.le-brise-glace.com/',
  },
  {
    imgUrl: 'organizers/totaal-rez-logo.jpg',
    name: 'Totaal Rez',
    website: 'https://www.totaalrez.com/',
  },
  {
    imgUrl: 'organizers/plages-electroniques-logo.svg',
    name: 'Les plages Electroniques',
    website: 'https://www.plages-electroniques.com/',
  },
  {
    imgUrl: 'organizers/antipode-logo.svg',
    name: 'Antipode',
    website: 'https://antipode-rennes.fr/',
  },
  {
    imgUrl: 'organizers/la-rodia-logo.svg',
    name: 'La Rodia',
    website: 'https://larodia.com/',
  },
  {
    imgUrl: 'organizers/cartel-logo.svg',
    name: 'Cartel',
    website: 'https://cartel.bzh/',
  },
  {
    imgUrl: 'organizers/petites-folies-logo.png',
    name: 'Les petites folies',
    website: 'https://www.lespetitesfolies-iroise.com/',
  },
  {
    imgUrl: 'organizers/le-cargo-logo.svg',
    name: 'Le Cargö',
    website: 'https://lecargo.fr/',
  },
  {
    imgUrl: 'organizers/les-zeclectiques-logo.svg',
    name: "Les Z'Eclectiques",
    website: 'http://www.leszeclectiques.com/',
  },
  {
    imgUrl: 'organizers/festival-de-la-paille-logo.jpg',
    name: 'Festival de la paille',
    website: 'https://www.festivalpaille.fr/',
  },
  {
    imgUrl: 'organizers/normandy-logo.png',
    name: 'Le Normandy',
    website: 'https://www.lenormandy.net',
  },
  {
    imgUrl: 'organizers/krumpp-logo.png',
    name: 'Krumpp',
    website: 'https://krumpp.fr',
  },
  {
    imgUrl: 'organizers/bobital-logo.png',
    name: 'Bobital',
    website: 'https://www.bobital-festival.fr/',
  },
  {
    imgUrl: 'organizers/24h-de-l-insa-logo.svg',
    name: '24h de l\'INSA',
    website: 'https://www.24heures.org/',
  },
  {
    imgUrl: 'organizers/terres-du-son-logo.svg',
    name: 'Terres du Son',
    website: 'https://terresduson.com/',
  },
  {
    imgUrl: 'organizers/sounds-like-hell-logo.png',
    name: 'Sounds Like Hell',
    website: 'https://www.facebook.com/slhproductions/',
  },
  {
    imgUrl: 'organizers/ecaussysteme-logo.png',
    name: 'Festival Ecaussysteme',
    website: 'https://www.ecaussysteme.com/',
  },
  {
    imgUrl: 'organizers/festi-dreuz-logo.png',
    name: 'Festi Dreuz',
    website: 'https://www.festidreuz.fr/',
  },
  {
    imgUrl: 'organizers/ndk-festival-logo.png',
    name: 'NDK festival',
    website: 'https://ndkfestival.com/',
  },
  {
    imgUrl: 'organizers/grandes-marees-logo.svg',
    name: 'Grandes Marées',
    website: 'http://festivalgrandesmarees.com/',
  },
  {
    imgUrl: 'organizers/w-spectacle-logo.png',
    name: 'W Spectacle',
    website: 'https://wspectacle.fr/',
  },
  {
    imgUrl: 'organizers/tannerie-logo.svg',
    name: 'La Tannerie',
    website: 'https://www.la-tannerie.com/',
  },
  {
    imgUrl: 'organizers/nuit-des-etoiles-logo.png',
    name: 'La Nuit des Etoiles',
    website: 'https://www.festival-nuitdesetoiles.fr/',
  },
  {
    imgUrl: 'organizers/ouest-park-logo.svg',
    name: 'Ouest Park',
    website: 'https://www.ouestpark.com/',
  },
  // {
  //   imgUrl: 'organizers/dentelles-electroniques-logo.png',
  //   name: 'Les Dentelles Electroniques',
  //   website: 'https://lesdentelleselectroniques.com/'
  // },
  {
    imgUrl: 'organizers/infamous-armada-logo.png',
    name: 'Infamous Armada',
    website: 'https://infamous-armada.fr/',
  },
  {
    imgUrl: 'organizers/chien-a-plumes-logo.jpg',
    name: 'Chien à plumes',
    website: 'https://www.chienaplumes.fr/',
  },
  {
    imgUrl: 'organizers/welcome-in-tziganie-logo.png',
    name: 'Welcome in Tziganie',
    website: 'https://www.auch-tourisme.com/agenda/welcome-in-tziganie/',
  },
  {
    imgUrl: 'organizers/son-dgaston-logo.png',
    name: 'Festival Le son d\'Gaston',
    website: 'https://lesondgaston.fr/',
  },
  {
    imgUrl: 'organizers/festouaze-logo.png',
    name: 'Fest\'ouaze festival',
    website: 'https://www.facebook.com/p/FestOuaz%C3%A9-100073750702880',
  },
  {
    imgUrl: 'organizers/check-in-party-logo.png',
    name: 'Check-In Party',
    website: 'https://www.checkinparty.com/',
  },
  {
    imgUrl: 'organizers/fest-noise-logo.png',
    name: 'Fest Noise',
    website: 'https://www.festnoise.fr/',
  },
  {
    imgUrl: 'organizers/herisson-prod-logo.svg',
    name: 'Hérisson Prod.',
    website: 'https://herisson-prod.com/',
  },
  //  {
  //   imgUrl: 'organizers/festival-ambert-logo.svg',
  //  name: 'Festival Ambert',
  // website: 'https://festival-ambert.fr/',
  // },
  {
    imgUrl: 'organizers/festival-au-fil-du-son-logo.svg',
    name: 'Festival au Fil du Son',
    website: 'https://www.lachmiseverte.com/',
  },
  {
    imgUrl: 'organizers/marseille-jazz-des-cinq-continents-logo.svg',
    name: 'Marseille Jazz des Cinq Continents',
    website: 'https://www.marseillejazz.com/',
  },
  {
    imgUrl: 'organizers/bre-tone-logo.png',
    name: 'Bre.Tone',
    website: 'https://www.facebook.com/asso.bre.tone/',
  },
  {
    imgUrl: 'organizers/festival-attrapsons-logo.png',
    name: 'Festival Attrap\'Sons',
    website: 'https://www.attrapsons.fr/',
  },
  {
    imgUrl: 'organizers/festival-decibulles-logo.svg',
    name: 'Festival Décibulles',
    website: 'https://www.decibulles.com/',
  },
  {
    imgUrl: 'organizers/festival-au-pont-du-rock-logo.png',
    name: 'Festival Au Pont du Rock',
    website: 'https://www.aupontdurock.com/',
  },
  {
    imgUrl: 'organizers/belle-electrique-logo.png',
    name: 'La belle électrique',
    website: 'https://www.la-belle-electrique.com/',
  },
  {
    imgUrl: 'organizers/lo-bolegason-logo.png',
    name: 'Lo Bolegason',
    website: 'http://www.bolegason.org/',
  },
  {
    imgUrl: 'organizers/la-sirene-logo.png',
    name: 'La sirène',
    website: 'https://la-sirene.fr/',
  },
  {
    imgUrl: 'organizers/souris-verte-logo.png',
    name: 'La souris verte',
    website: 'https://lasourisverte-epinal.fr/',
  },
  {
    imgUrl: 'organizers/opera-vichy-logo.png',
    name: 'Opéra de Vichy',
    website: 'https://opera-vichy.com/',
  },
  {
    imgUrl: 'organizers/superforma-logo.png',
    name: 'Superforma',
    website: 'https://superforma.fr/',
  },
  {
    imgUrl: 'organizers/sax-logo.png',
    name: 'Le Sax',
    website: 'https://lesax-acheres78.fr/',
  },
  {
    imgUrl: 'organizers/noumatrouff-logo.png',
    name: 'Noumatrouff',
    website: 'https://www.noumatrouff.fr/',
  },
  {
    imgUrl: 'organizers/plages-magnetiques-logo.png',
    name: 'Les plages magnétiques',
    website: 'https://www.plages-magnetiques.org/',
  },
  {
    imgUrl: 'organizers/6-par-4-logo.png',
    name: 'Le 6 par 4',
    website: 'https://www.6par4.com/',
  },
  //  {
  //  imgUrl: 'organizers/festival-artrock-logo.svg',
  // name: 'Festival Art Rock',
  // website: 'https://www.artrock.org/',
  // },
  {
    imgUrl: 'organizers/festival-visions999-logo.png',
    name: 'Festival Visions',
    website: 'https://www.festivalvisions.com/',
  },
  {
    imgUrl: 'organizers/electrolapse-logo.svg',
    name: 'Festival Electrolapse',
    website: 'https://electrolapse.fr/',
  },
  {
    imgUrl: 'organizers/evenstar-reggae-festival-logo.png',
    name: 'Evenstar Reggae Festival',
    website: 'https://evenstarfestival.fr/',
  },
  {
    imgUrl: 'organizers/montagne-en-scene-logo.png',
    name: 'Montagne en scène',
    website: 'https://www.montagne-en-scene.com/',
  },
  {
    imgUrl: 'organizers/sirk-festival-logo.png',
    name: 'Le SIRK Festival',
    website: 'https://lesirkfestival.com/',
  },
  /* TODO uncomment when contract will be signed
  {
    imgUrl: 'organizers/musicalarue-logo.png',
    name: 'Musicalarue',
    website: 'http://www.musicalarue.com/fr'
  },*/

];

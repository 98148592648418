import { Directive, HostListener, Input, inject } from '@angular/core';
import { TrackingService } from '../../services';

@Directive({
  selector: '[appTrack]',
  standalone: true,
})
export class TrackingDirective {
  private trackingService = inject(TrackingService);

  @Input('appTrack') event: {category: string, action: string, name: string, value?: number, customDimension?: {}};

  @HostListener('click')
  onClick() {
    this.trackingService.addEvent(this.event);
  }

}

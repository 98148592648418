import { Injectable, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class RouterService {
  private router = inject(Router);


  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor() {
    const router = this.router;

    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          !this.currentUrl.startsWith('/page-introuvable') &&
          !this.currentUrl.startsWith('/reinitialisation-de-mot-de-passe') &&
          !this.currentUrl.startsWith('/reset-password')
        ) {
          // we save only last valid url
          this.previousUrl = this.currentUrl;
        }
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }
}

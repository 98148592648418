import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EventsService } from '../../services';
import { EventStore } from '../../stores';
import { Event } from '../../models';
import { Platform } from '@angular/cdk/platform';

// todo migrate to resolve function
// How to handle the currentEvent ? => user service ?
@Injectable({
  providedIn: 'root',
})
export class PublicEventResolver  {
  private router = inject(Router);
  private eventsService = inject(EventsService);
  private eventStore = inject(EventStore);
  private platform = inject(Platform);

  private currentEvent: Event;

  resolve(route: ActivatedRouteSnapshot) {
    const eventId = route.pathFromRoot.find((path) => !!path.params.eventId)?.params?.eventId;
    const eventUrl = route.pathFromRoot.find((path) => !!path.params.eventUrl)?.params?.eventUrl;
    if (this.currentEvent && (this.currentEvent.id === eventId || this.currentEvent.url === eventUrl)) {
      this.eventStore.setCurrentEvent(this.currentEvent.id);
      return of(this.currentEvent);
    }
    return this.eventsService.getEventIfAvailable(eventId, eventUrl).pipe(
      tap((e) => {
        this.currentEvent = new Event(e);

        this.eventStore.loadEvents([this.currentEvent]);
        this.eventStore.setCurrentEvent(this.currentEvent.id);

        if (!this.platform.isBrowser) {
          return of(this.currentEvent);
        }

        const currentUrl = route.pathFromRoot.reduce((url, route) => [url, ...route.url.map((s) => s.toString())].filter((s) => !!s).join('/'), '/');
        const basePath = currentUrl.substring(
          0,
          currentUrl.indexOf(this.currentEvent.url) + this.currentEvent.url.length,
        );
        if (!['online', 'private'].includes(this.currentEvent.status) && currentUrl !== basePath) {
          this.router.navigate([basePath]);
          return EMPTY;
        }
        const allSellDeactivated = !this.currentEvent.TicketingPlatforms?.find((tp) => !tp.saleDisabled);
        const allPurchaseDeactivated = !this.currentEvent.TicketingPlatforms?.find((tp) => !tp.purchaseDisabled);
        const goToPurchaseRoute = !!route.url.find((o) => o.path === 'achat');
        const goToSellRoute = !!route.url.find((o) => o.path === 'vente');
        if (
          (allPurchaseDeactivated && goToPurchaseRoute)
          || (goToSellRoute && allSellDeactivated)
        ) {
          this.router.navigate([basePath]);
          return EMPTY;
        }
        if (
          route.params.eventUrl &&
          this.currentEvent.url &&
          route.params.eventUrl !== this.currentEvent.url
        ) {
          this.router.navigate(
            [currentUrl.replace(route.params.eventUrl, this.currentEvent.url)],
            {
              queryParams: route.queryParams,
            },
          );
          return EMPTY;
        }
        return EMPTY;
      }),
      catchError((err) => {
        this.router.navigate(['404']);
        return EMPTY;
      }),
    );
  }

}

import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { Language } from '../../../models';
import { CheckboxFormFieldComponent } from '../inputs/checkbox-form-field/checkbox-form-field.component';
import { MatError } from '@angular/material/form-field';

@Component({
  selector: 'app-custom-checkbox-fields',
  templateUrl: './custom-checkbox-fields.component.html',
  styleUrls: ['./custom-checkbox-fields.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    CheckboxFormFieldComponent,
    MatError,
  ],
})
export class CustomCheckboxFieldsComponent implements OnInit, OnDestroy {
  private translocoService = inject(TranslocoService);


  @Input() customCheckboxFields: Array<{[key in Language]?: string}>;
  @Input() form: UntypedFormGroup;

  private langSub: Subscription;
  public currentLang: string;

  getErrorCheckbox(form: UntypedFormGroup, index: number) {
    const control = form.get(this.currentLang + index);
    if (!!control && !!control.touched && !!control.errors) {
      return Object.keys(control.errors)[0];
    }
    return null;
  }

  ngOnInit(): void {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }


}

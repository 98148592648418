import { Component, Input, OnInit, OnDestroy, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription, first } from 'rxjs';
import * as Diff from 'diff';
import { AppService, AuthService, ColorService, ContactService } from '../../../services';
import { ContactInfo } from '../../../models';
import { environment } from '../../../../environments/environment';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Utils } from '../../utils';
import { HelpStore } from '../../../stores';
import { EmailCheckValidator } from '../../../validators';
import { formatDate, NgClass, AsyncPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LetDirective } from '../../directives/let.directive';
import { MatFormField, MatLabel, MatError, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TrimDirective } from '../../directives/trim.directive';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { LoaderComponent } from '../loader/loader.component';
import { MatButton } from '@angular/material/button';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { SafePipe } from '../../../pipes/safe.pipe';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    LetDirective,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    TrimDirective,
    MatError,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatHint,
    MatButtonToggleGroup,
    MatButtonToggle,
    LoaderComponent,
    MatButton,
    NgClass,
    AsyncPipe,
    SafePipe,
    TranslocoLocaleModule,
  ],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  appService = inject(AppService);
  colorService = inject(ColorService);
  helpStore = inject(HelpStore);
  private route = inject(ActivatedRoute);
  private authService = inject(AuthService);
  private contactService = inject(ContactService);
  private translocoService = inject(TranslocoService);
  private emailCheckValidator = inject(EmailCheckValidator);

  @Input() hasCustomColor: boolean;
  @Input() withMailBox = true;
  @Input() textSize;

  mailObjects: Array<string> = ['missingEvent', 'purchase', 'sell', 'help', 'organizer', 'data', 'parternship', 'security', 'other'];
  sendingDate: Date;
  errors = [];
  isSendingMessage = false;
  environmentName = environment.envName !== 'prod' ? `[${environment.envName}] ` : '';

  private contactSub: Subscription;
  private formSub: Subscription;
  private userSub: Subscription;
  private translationSub: Subscription;
  private translations;

  public contactForm: UntypedFormGroup;

  ngOnInit() {
    this.contactForm = new UntypedFormGroup({
      fullName: new UntypedFormControl('', [Validators.pattern(Utils.nameRegex)]),
      mailAddress: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [this.emailCheckValidator.validate.bind(this.emailCheckValidator)],
      }),
      subject: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.maxLength(1000)]),
    });
    this.contactSub = this.helpStore.contactInfo$.pipe(first()).subscribe( (newInfo) => {
      this.contactForm.patchValue(newInfo);
      if (!this.withMailBox) {
        if (newInfo.mailAddress) {
          this.contactForm.removeControl('mailAddress');
        }
        if (newInfo.fullName) {
          this.contactForm.removeControl('fullName');
        }
        if (newInfo.subject) {
          this.contactForm.removeControl('subject');
        }
      }
    });

    this.userSub = this.authService.getUser().subscribe((user) => {
      this.contactForm.patchValue({
        fullName: [user?.firstname, user?.lastname?.toUpperCase()].filter((v) => v && v !== '').join(' '),
        mailAddress: user?.email,
      });
    });

    this.formSub = this.contactForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged( (prev, curr) => Diff.diffJson(prev, curr).every((o) => !o.added && !o.removed)),
    ).subscribe( () => {
      const formValue = this.contactForm.getRawValue(); // retrieve disabled controls value as well
      if (['purchase', 'sell', 'help', 'organizer'].includes(formValue.subject)) {
        if (!this.contactForm.controls.event && this.withMailBox) {
          this.contactForm.addControl('event', new UntypedFormControl('', [Validators.required]));
        }
      } else {
        this.contactForm.removeControl('event');
      }
      if (formValue.subject === 'missingEvent') {
        if (!this.contactForm.controls.missingEventDate && this.withMailBox) {
          this.contactForm.addControl('missingEventName', new UntypedFormControl('', [Validators.required]));
          this.contactForm.addControl('missingEventDate', new UntypedFormControl('', []));
          this.contactForm.addControl('missingEventType', new UntypedFormControl('', [Validators.required]));
        }
      } else {
        this.contactForm.removeControl('missingEventDate');
        this.contactForm.removeControl('missingEventName');
        this.contactForm.removeControl('missingEventType');
      }
      this.helpStore.loadContactInfo({
        ...this.helpStore.getContactInfo(),
        ...this.contactForm.getRawValue(),
        context: this.helpStore.getContext(),
      });
    });
    const paramSubject = this.route.snapshot.queryParams?.subject;
    if (paramSubject && this.mailObjects.includes(paramSubject)) {
      this.contactForm.patchValue({subject: paramSubject ?? ''});
      this.contactForm.controls.subject.disable();
    }
    this.translationSub = this.translocoService.selectTranslateObject('contact').subscribe((translations) => this.translations = translations);
  }

  getError(formControlName: string) {
    const control = this.contactForm.get(formControlName);
    if (!!control && !!control.errors && !!control.touched) {
      return Object.keys(control.errors)[0];
    }
    return null;
  }

  onSubmitClick() {
    this.contactForm.markAllAsTouched();
    this.sendingDate = null;
    this.errors = [];
    if (!this.contactForm.valid) {
      this.errors = ['invalidForm'];
      this.isSendingMessage = false;
      return;
    }
    this.isSendingMessage = true;
    const contactInfo = this.helpStore.getContactInfo();
    const object = contactInfo.subject;

    const contactPayload: ContactInfo = {
      fullName: contactInfo.fullName ?? 'missing user name',
      mailAddress: contactInfo.mailAddress,
      subject: `${this.environmentName}${this.translations[object] ?? object}`,
      message: contactInfo.event
        ? 'Événement : ' + contactInfo.event + '\n\n Message : \n' + contactInfo.message
        : contactInfo.missingEventName
          ? 'Événement manquant : ' + contactInfo.missingEventName + (contactInfo?.missingEventDate ? ('\n\n Date : ' + formatDate(contactInfo.missingEventDate, 'fullDate', 'fr')) : '') + '\n\n Type : ' + this.translocoService.translate('contact.' +contactInfo.missingEventType) + '\n\n Message : \n' + contactInfo.message
          : contactInfo.message,
      missingEventName: contactInfo?.missingEventName,
    };

    if (contactInfo.context) {
      contactPayload.message = contactPayload.message.concat('\n\n---CONTEXTE---\n' + contactInfo.context);
    }
    this.contactService.sendMessage(contactPayload).subscribe({
      next: () => {
        this.isSendingMessage = false;
        this.sendingDate = new Date();
        if (this.contactForm.contains('event')) {
          this.contactForm.removeControl('event');
        }
        if (this.contactForm.contains('missingEventName')) {
          this.contactForm.removeControl('missingEventDate');
          this.contactForm.removeControl('missingEventName');
          this.contactForm.removeControl('missingEventType');
        }
        this.contactForm.controls.subject?.reset();
        this.contactForm.controls.message?.reset();
        this.contactForm.controls.event?.reset();
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on onSubmitClick function on contact component : ', err);
        if (err.error.ResultCode === 999975) {
          this.errors.push('emailBlocked');
          this.errors.push('emailSendingError');
        } else {
          this.errors.push('messageNotSent');
          this.errors.push('checkInformationsProvided');
          this.errors.push('emailSendingError');
        }
        this.isSendingMessage = false;
      },
    });
  }

  ngOnDestroy() {
    this.formSub?.unsubscribe();
    this.translationSub?.unsubscribe();
    this.contactSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }
}

import { isPlatformBrowser, NgClass } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { Event, Ticket, TicketStatus } from '../../../models';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../services';
import { Subscription } from 'rxjs';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { SocialMediaPreviewComponent } from '../social-media-preview/social-media-preview.component';
import { MatTooltip } from '@angular/material/tooltip';
import { TrackingDirective } from '../../directives/tracking.directive';
import { MatIconButton } from '@angular/material/button';
import { MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-ticket-sharing',
  templateUrl: './ticket-sharing.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    SocialMediaPreviewComponent,
    MatTooltip,
    TrackingDirective,
    NgClass,
    MatIconButton,
    MatSuffix,
    MatIcon,
  ],
})
export class TicketSharingComponent implements OnChanges, OnDestroy {
  private notificationService = inject(NotificationService);
  private translocoService = inject(TranslocoService);

  environment = environment;
  @Input() public event: Event;
  @Input() public tickets: Array<Partial<Ticket>>;
  announceUrls: {[ticketId: number]: string};
  isBrowser = false;
  translationSub: Subscription;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnChanges() {
    this.setAnnounceUrl();
    this.translationSub = this.translocoService.selectTranslate('ticketSharing').subscribe();
  }

  setAnnounceUrl() {
    this.announceUrls = {};
    this.tickets.forEach((ticket) => {
      let announceUrl;
      if (ticket.id) {
        if (this.event?.url) {
          announceUrl = this.environment.domain + 'e/n/' + this.event.url + '/achat/' + ticket.id;
        } else {
          announceUrl = this.environment.domain + 'evenement/' + this.event.id + '/achat/' + ticket.id;
        }
        if ([TicketStatus.PrivateSale, TicketStatus.FriendSale].includes(ticket.status)) {
          announceUrl = announceUrl.concat(`?token=${ticket.privateSaleToken}`);
          if (ticket.directPurchaseToken) {
            announceUrl = announceUrl.concat(`&entryToken=${ticket.directPurchaseToken}`);
          }
        }
      }
      this.announceUrls[ticket.id] = announceUrl;
    });
  }

  copyAnnounceURL(ticketId: number) {
    if (this.isBrowser) {
      navigator?.clipboard?.writeText(this.announceUrls[ticketId]).then(() => {
        this.notificationService.open(this.translocoService.translate('clipboard.wellCopied'));
      }, (err) => {
        this.notificationService.open(this.translocoService.translate('clipboard.copyError'));
      });
    }
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostBinding, HostListener, Input, PLATFORM_ID, inject } from '@angular/core';
import { NotificationService } from '../../services';
import { TranslocoService } from '@ngneat/transloco';

@Directive({
  selector: '[appClipboard]',
  standalone: true,
})
export class ClipboardDirective {
  private notificationService = inject(NotificationService);
  private translocoService = inject(TranslocoService);
  private elementRef = inject(ElementRef);

  @HostBinding('style.cursor')  cursor:string = 'pointer';
  @Input('appClipboard') text?: string;
  private isBrowser = false;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('click')
  onClick() {
    if (this.isBrowser) {
      const textToCopy = this.text ?? this.elementRef.nativeElement.innerText;
      navigator?.clipboard?.writeText(textToCopy).then(() => {
        this.notificationService.open(this.translocoService.translate('clipboard.wellCopied'));
      }, (err) => {
        this.notificationService.open(this.translocoService.translate('clipboard.copyError'));
      });
    }
  }

}

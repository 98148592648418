import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CustomTicketField, Language, LanguageMap } from '../../../models';
import { Utils } from '../../utils';
import { KeyValuePipe } from '@angular/common';
import { TextFormFieldComponent } from '../inputs/text-form-field/text-form-field.component';
import { NumericFormFieldComponent } from '../inputs/numeric-form-field/numeric-form-field.component';
import { CheckboxFormFieldComponent } from '../inputs/checkbox-form-field/checkbox-form-field.component';
import { DateFormFieldComponent } from '../inputs/date-form-field/date-form-field.component';

@Component({
  selector: 'app-custom-ticket-fields',
  templateUrl: './custom-ticket-fields.component.html',
  styleUrls: ['./custom-ticket-fields.component.scss'],
  standalone: true,
  imports: [
    TextFormFieldComponent,
    NumericFormFieldComponent,
    CheckboxFormFieldComponent,
    DateFormFieldComponent,
    KeyValuePipe,
  ],
})
export class CustomTicketFieldsComponent implements OnInit {
  private translocoService = inject(TranslocoService);

  @Input() JSONfields: {[index: number]: CustomTicketField};
  @Input() eventId: number;
  customTicketFields: {[index: number]: CustomTicketField};
  formGroup = new FormGroup({});
  @Input() public memorisedValues: {customTicketAnswers?: {[fieldId: number]: unknown}};
  @Input() set form(parentForm: FormGroup) {
    if (!parentForm.contains('customTicketAnswers')) {
      parentForm.addControl('customTicketAnswers', this.formGroup);
    }
  }

  public Language = Language;
  public languages = Object.values(this.Language);

  ngOnInit(): void {
    this.customTicketFields = this.JSONfields;
    if (!this.memorisedValues?.customTicketAnswers) {
      this.memorisedValues.customTicketAnswers = {};
    }
  }

  getName(names: LanguageMap) {
    return Utils.getTranslation(names, this.translocoService.translate('language.localeCountry'));
  }

}

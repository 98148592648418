import { Component, OnInit, inject } from '@angular/core';
import { NavigationService } from '../../../services';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class BackButtonComponent implements OnInit {
  navigation = inject(NavigationService);


  ngOnInit(): void {
    this.navigation.startSaveHistory();
  }

}

import { Platforms, LanguageMap, EventStatus, UserProfile } from '.';

export enum OrganizerAccessRight {
  Guest = 'guest',
  Owner = 'owner',
}

export interface OrganizerEventPreference {
  eventTemplates: {
    [eventModelId: number]: {
      order: number; // int apply preferences from smallest to biggest model order
      Organizers: boolean;
      EventPersonalization: boolean;
      Categories: boolean;
      TicketTemplates: boolean;
      WaitListConfig: boolean;
      Automations: boolean;
    };
  };
  categoryPriceScale: number;
  defaultStatus?: EventStatus;
}

export class Organizer {
  id: number;
  name: string;
  url: string;
  color: string;
  backgroundColor: string;
  logoUrl: string;
  eventsPageText: string;
  EventOrganizer: {accessRight: OrganizerAccessRight};
  platformsForeignIds: {[key in Platforms]: string};
  platformsCredentials: {[key in Platforms]: {
    baseUrl: string;
    clientId: string;
    clientSecret: string;
  }};
  eventPreferences: OrganizerEventPreference;
  public iframeTitle: LanguageMap;
  AssosiatedUsers?: UserProfile[];

  constructor(options?: any) {
    Object.assign(this, options);
    this.platformsForeignIds = options?.platformsForeignIds ?? {};
    this.platformsCredentials = options?.platformsCredentials ?? {};
    this.EventOrganizer = options?.EventOrganizer ?? {accessRight: OrganizerAccessRight.Guest};
    this.eventPreferences = options?.eventPreferences ?? {eventTemplates: {}};
    if (!this.iframeTitle) {
      this.iframeTitle = {};
    }
  }

  get accessRight() {
    return this.EventOrganizer?.accessRight;
  }

  set accessRight(r: OrganizerAccessRight) {
    if (!this.EventOrganizer) {
      return;
    }
    this.EventOrganizer.accessRight = r;
  }
}

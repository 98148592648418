import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { institutionPartners } from '../../../assets/ressources/institution-partners';
import { AppService } from '../../services/app.service';
import { TranslocoModule } from '@ngneat/transloco';
import { MatToolbar } from '@angular/material/toolbar';
import { ReelaxTicketsLogoComponent } from '../../shared/components/reelax-tickets-logo/reelax-tickets-logo.component';
import { MatTooltip } from '@angular/material/tooltip';
import { LanguageSelectorComponent } from '../../shared/components/language-selector/language-selector.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatToolbar,
    ReelaxTicketsLogoComponent,
    RouterLink,
    RouterLinkActive,
    MatTooltip,
    LanguageSelectorComponent,
  ],
})
export class FooterComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private appService = inject(AppService);

  public hideFooter = false;
  public addSecurityMargin = false;
  public currentYear: number;
  public institutionPartners: Array<{imgUrl: string, name: string, website: string}> = institutionPartners;

  private routerSub: Subscription;
  private langSub: Subscription;
  private settingsSub: Subscription;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.handleFooterHidding(this.router.url);
    this.routerSub = this.router.events.pipe(
      filter((val) => val instanceof NavigationEnd),
    ).subscribe((val: NavigationEnd) => {
      this.handleFooterHidding(val.url);
    });
    this.settingsSub = this.appService.settings.subscribe( (settings) => {
      if (settings.isWidget) {
        this.hideFooter = true;
      }
    });
  }

  handleFooterHidding(url: string) {
    if (['/admin', '/orga', '/connexion'].some( (k) => url.indexOf(k) === 0)) {
      this.hideFooter = true;
      this.addSecurityMargin = false;
    } else if (['/e/n', '/evenement/', '/three-d-secure', '/confirmation', '/mon-compte'].some( (k) => url.indexOf(k) === 0)) {
      this.hideFooter = true;
      this.addSecurityMargin = true;
    } else if (!this.appService.settings.value?.isWidget) {
      this.hideFooter = false;
    }
  }

  ngOnDestroy() {
    this.routerSub?.unsubscribe();
    this.langSub?.unsubscribe();
    this.settingsSub?.unsubscribe();
  }
}

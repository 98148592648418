import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface FingerprintObj {
  data;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  private platformId = inject(PLATFORM_ID);

  private fingerprint: FingerprintObj;
  private calculating = false;

  get() {
    if (isPlatformBrowser(this.platformId)) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<FingerprintObj>( async (resolve, reject) => {
        if (!!this.fingerprint || this.calculating) {
          while (!this.fingerprint) { // define the condition as you like
            await new Promise((res) => setTimeout(res, 1000));
          }
          resolve(this.fingerprint);
        } else {
          this.calculating = true;
          const fpPromise = FingerprintJS.load({
            monitoring: false,
          });
          fpPromise
            .then((fp) => fp.get())
            .then((result) => {
              this.fingerprint = {
                id: result.visitorId,
                data: result.components,
              };
              resolve(this.fingerprint);
            });
        }
      });
    }
    return undefined;
  }

}

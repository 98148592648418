import { Component, Input, inject } from '@angular/core';
import { SEOService } from '../../../services';
import { Event, Ticket } from '../../../models';
import { TranslocoModule } from '@ngneat/transloco';
import { MatCard } from '@angular/material/card';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-social-media-preview',
  templateUrl: './social-media-preview.component.html',
  styleUrls: ['./social-media-preview.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatCard,
    DecimalPipe,
  ],
})
export class SocialMediaPreviewComponent {
  seoService = inject(SEOService);

  @Input() event: Event;
  @Input() ticket: Partial<Ticket>;
  @Input() type: 'sell' | 'buy';

}

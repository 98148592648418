import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { debounceTime, filter, forkJoin, from, Subject, Subscription, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AlertMessageDialogComponent } from '../components/alert-message-dialog/alert-message-dialog.component';
import { AlertMessageStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageService implements OnDestroy {
  private dialog = inject(MatDialog);
  private alertMessageStore = inject(AlertMessageStore);
  private http = inject(HttpClient);

  private configUrl = environment.apiUrl;
  private lastAlert: {time: number};
  private alertSub: Subscription;
  private alertTime = 5 * 60000; // 5 minutes

  public openAlert = new Subject();

  constructor() {
    this.listenAlert();
  }

  private listenAlert() {
    this.alertSub = this.openAlert.pipe(
      debounceTime(100),
      filter( () => !this.lastAlert || new Date(this.lastAlert?.time) < (new Date(Date.now() - this.alertTime))),
      switchMap( () => forkJoin({
        message: this.getAlertMessage(),
        lastAlert: from( this.alertMessageStore.getAlert() ),
      })),
    ).subscribe( ({message, lastAlert}) => {
      if (!lastAlert || new Date(lastAlert?.time) < (new Date(Date.now() - this.alertTime))) {
        this.alertMessageStore.setAlert();
        this.lastAlert = {time: new Date().getTime()};
        if (message?.alertMessage?.length > 1) {
          this.dialog.open(AlertMessageDialogComponent, {
            data: {
              button: 'Ok',
              message: message.alertMessage,
            },
            scrollStrategy: new NoopScrollStrategy(), // allows to scroll with opened dialog
          });
        }
      } else if (lastAlert?.time) {
        this.lastAlert = lastAlert;
      }
    });
  }

  private getAlertMessage() {
    const url = `${this.configUrl}r/s/alertMessage`;
    return this.http.get<{alertMessage: string}>(url);
  }

  ngOnDestroy(): void {
    this.alertSub?.unsubscribe();
  }

}

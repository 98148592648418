import { Injectable, OnDestroy, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, take, map, filter, switchMap, shareReplay, tap, mergeWith } from 'rxjs/operators';
import { of, Observable, timer, throwError, BehaviorSubject, NEVER, Subscription, Subject, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { Credentials, Language, UserProfile, WebToken } from '../models';
import { TokenStore, UserStore } from '../stores';
import { StorageService } from '.';
import { TranslocoService } from '@ngneat/transloco';
import { IframeService } from './iframe.service';
import { Platform } from '@angular/cdk/platform';

const NORMAL_REFRESH_INTERVAL = 365 * 24 * 60 * 60 * 1000; // 1 year
const FAST_REFRESH_INTERVAL = 5000; // 5 seconds
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private http = inject(HttpClient);
  private userStore = inject(UserStore);
  private translocoService = inject(TranslocoService);
  private storageService = inject(StorageService);
  private tokenStore = inject(TokenStore);
  private iframeService = inject(IframeService);
  private platform = inject(Platform);

  private userCache$: Observable<UserProfile>;
  private reloadUser$ = new BehaviorSubject(true);
  private pushUser$ = new Subject<UserProfile>();
  private langSub: Subscription;

  private configUrl = environment.apiUrl;

  public redirectUrl: string;
  private currentLang = Language.frFR;

  private userAutoRefreshRequests = 0;
  private userRefreshInterval = NORMAL_REFRESH_INTERVAL;
  private isBrowser: boolean;
  private isApple = false;

  public webToken = new WebToken();

  constructor() {
    this.isBrowser = this.platform.isBrowser;
    this.isApple = this.platform.SAFARI || this.platform.IOS;
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  public getUser() {
    if (!this.userCache$ ) {
      // Set up timer that ticks every X milliseconds when toggled on
      const toggle$ = new BehaviorSubject(true);
      const timer$ = toggle$.pipe(
        switchMap( (paused) => paused ? timer(0, this.userRefreshInterval) : NEVER),
      );

      // For each timer tick make an http request to fetch new data
      // We use shareReplay(X) to multicast the cache so that all
      // subscribers share one underlying source and don't re-create
      // the source over and over again. We use takeUntil to complete
      // this stream when the user forces an update.
      this.userCache$ = this.reloadUser$.asObservable().pipe(
        filter( () => this.isBrowser),
        tap( () => {
          toggle$.next(true);
        }),
        switchMap(() => timer$),
        switchMap(() => this.requestUser() ),
        mergeWith( this.pushUser$.asObservable() ),
        tap( (u) => {
          if (!u) {
            this.webToken = undefined;
            toggle$.next(false);
          } else {
            // check if current user changed
            // handle case where user changed from anon to account owner
            this.userStore.getUser().then( (browserUser) => {
              if (browserUser && browserUser.id !== u.id) {
                return this.storageService.removeAllItem();
              }
              return Promise.resolve();
            }).then( () => this.userStore.setUser({id: u.id}) );
          }
        }),
        shareReplay(CACHE_SIZE),
      );
    }

    return this.userCache$;
  }

  public updateUserFront(user: UserProfile, changes: Partial<UserProfile>) {
    Object.assign(user, changes);
    this.pushUser$.next(user);
  }

  public forceReload() {
    // Calling next will reload user manually
    this.reloadUser$.next(true);
  }

  public setUserAutoRefresh() {
    this.userAutoRefreshRequests += 1;
    if (this.userAutoRefreshRequests === 1) {
      this.userRefreshInterval = FAST_REFRESH_INTERVAL;
      this.forceReload(); // we need to forceReload so new interval is applied
    }
  }

  public disableUserAutoRefresh() {
    this.userAutoRefreshRequests -= 1;
    if (this.userAutoRefreshRequests === 0) {
      this.userRefreshInterval = NORMAL_REFRESH_INTERVAL;
      this.forceReload(); // we need to forceReload so new interval is applied
    } else if (this.userAutoRefreshRequests < 0) {
      throw new Error('user auto refresh interval below 0');
    }
  }

  // Helper method to actually fetch the user
  private requestUser() {
    const route = `${this.configUrl}me`;
    return this.http.get<UserProfile>(route).pipe(
      catchError((val) => of('In requestUser function, I caught: ', val)),
      map((user) => new UserProfile(user)),
      map((user) => {
        if (!user.id) {
          return null;
        }
        return user;
      }),
    );
  }

  private removeTokenStorage() {
    this.webToken = undefined;
    this.tokenStore.cleanToken();
  }

  logout() {
    const route = `${this.configUrl}logout`;
    return this.http.post(route, {}, {
      withCredentials: true, // send credentials on logout for backend to delete
    }).pipe(
      catchError((val) => of('In logout function, I caught: ', val)),
      take(1),
    ).subscribe( () => {
      this.pushUser$.next(undefined);
      // removes user stored data from storage : token, waitlists etc
      this.storageService.removeAllItem();
      this.webToken = undefined;
    });
  }

  login(credentials: Credentials) {
    const route = `${this.configUrl}login`;
    const iframeApple = this.iframeService.isIframed() && this.isApple;
    this.removeTokenStorage();
    return this.http.post(route, credentials, {
      headers: iframeApple ? new HttpHeaders({'X-RT-apple': 'true'}) : undefined,
    }).pipe(
      catchError((error) => this.handleAuthError(error)),
      filter((user) => user.hasOwnProperty('id')),
      map( (user) => {
        const u = new UserProfile(user);
        this.pushUser$.next(u);
        return u;
      }),
    );
  }

  loginFromAnon(credentials: Credentials) {
    const route = `${this.configUrl}login`;
    const iframeApple = this.iframeService.isIframed() && this.isApple;
    this.removeTokenStorage();
    return this.http.put(route, credentials, {
      headers: iframeApple ? new HttpHeaders({'X-RT-apple': 'true'}) : undefined,
    }).pipe(
      catchError((error) => this.handleAuthError(error)),
      filter((user) => user.hasOwnProperty('id')),
      map( (user) => {
        const u = new UserProfile(user);
        this.pushUser$.next(u);
        return u;
      }),
    );
  }

  register(credentials: Credentials, specificProcess = null) {
    const route = `${this.configUrl}register`;
    const iframeApple = this.iframeService.isIframed() && this.isApple;
    this.removeTokenStorage();
    return this.http.post(route, {
      ...credentials,
      specificProcess,
      language: this.currentLang,
    }, {
      headers: iframeApple ? new HttpHeaders({'X-RT-apple': 'true'}) : undefined,
    }).pipe(
      catchError((error) => this.handleAuthError(error)),
      filter((user) => user.hasOwnProperty('id')),
      map( (user) => {
        const u = new UserProfile(user);
        this.pushUser$.next(u);
        return u;
      }),
    );
  }

  registerFromAnon(credentials: Credentials, userId: number, specificProcess = null) {
    const route = `${this.configUrl}register`;
    const iframeApple = this.iframeService.isIframed() && this.isApple;
    this.removeTokenStorage();
    return this.http.put(route, {
      ...credentials,
      userInfoId: userId,
      specificProcess,
      language: this.currentLang,
    }, {
      headers: iframeApple ? new HttpHeaders({'X-RT-apple': 'true'}) : undefined,
    }).pipe(
      catchError((error) => this.handleAuthError(error)),
      filter((user) => user.hasOwnProperty('id')),
      map( (user) => {
        const u = new UserProfile(user);
        this.pushUser$.next(u);
        return u;
      }),
    );
  }

  /**
   * token as jwt allows for fast authentification
   * @returns updates authService state with a valid webtoken
   */
  public refreshToken() {
    // first check if a token is stored in localstorage
    return from( this.tokenStore.getToken() ).pipe(
      switchMap( (storedToken) => {
        this.webToken = new WebToken(storedToken);
        if (!this.webToken.isExpired) {
          return of({});
        }
        // stored token invalid => get a token with our session cookie
        const route = `${this.configUrl}authtoken`;
        return this.http.get(route, {
          withCredentials: true,
        }).pipe(
          // if user is not logged in we still want the observable to emit
          catchError( (res) => {
            if (res instanceof HttpErrorResponse) {
              if (res.status === 401) {
                return of({});
              }
            }
            throw res;
          }),
        );
      }),
    );
  }

  public sendNewPasswordMail(email: string, specificProcess = null) {
    const route = `${this.configUrl}sendNewPassword`;
    return this.http.put(route, {email, specificProcess, language: this.currentLang}).pipe(
      catchError((error) => this.handleAuthError(error)),
    );
  }

  public checkPasswordTokenValidity(userAccountId: number, token: string) {
    const route = `${this.configUrl}checkPasswordToken/${userAccountId}`;
    return this.http.put(route, {token}).pipe(
      catchError((error) => this.handleAuthError(error)),
    );
  }

  public resetPassword(userAccountId: number, password: string, token: string) {
    const route = `${this.configUrl}resetPassword/${userAccountId}`;
    return this.http.put(route, {password, token}).pipe(
      catchError((error) => this.handleAuthError(error)),
    );
  }

  public regeneratePasswordToken(userAccountId: number, token: string, specificProcess = null) {
    const route = `${this.configUrl}regeneratePasswordToken/${userAccountId}`;
    return this.http.put(route, {token, specificProcess}).pipe(
      catchError((error) => this.handleAuthError(error)),
    );
  }

  private handleAuthError(error: HttpErrorResponse) {
    // eslint-disable-next-line no-console
    console.log('handleAuthError', error);
    if (['999966', '999945'].includes(error.error?.error)) {
      throw error;
    }
    // TODO make difference between server and user errors
    return throwError('Error! something went wrong into authService.');
  }

  public checkPasswordValidity(pswd){
    const route = `${this.configUrl}checkPasswordValidity`;
    return this.http.post<{isValid: boolean}>(route, { pswd });
  }

  ngOnDestroy() {
    this.langSub?.unsubscribe();
  }
}

import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { isPlatformBrowser, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ContentChild, Input, PLATFORM_ID, TemplateRef, ViewChild, inject } from '@angular/core';
import { DragScrollDirective } from '../../directives/drag-scroll.directive';

@Component({
  selector: 'app-carousel-scroll',
  templateUrl: './carousel-scroll.component.html',
  styleUrls: ['./carousel-scroll.component.scss'],
  standalone: true,
  imports: [
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    DragScrollDirective,
    CdkVirtualForOf,
    NgTemplateOutlet,
  ],
})
export class CarouselScrollComponent<Item> implements AfterViewInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('carousel_viewport') viewport: CdkVirtualScrollViewport;
  @Input() items: Item[];
  @Input() maxItems: number;
  @Input() autoScroll = true;
  @Input() buttons = false;
  @Input() itemSize: string;
  @Input() height?: string;

  public interval;
  private isBrowser;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    if (this.isBrowser ) {
      this.startAutoScroll();
    }
  }

  private startAutoScroll() {
    if (!this.autoScroll) {
      return;
    }
    let currentIndex = 0;
    let leftToRightDirection = true;

    this.interval = setInterval(() => {
      currentIndex = leftToRightDirection ?  currentIndex + 1 : currentIndex - 1;
      // change direction if we reach items limit
      if (currentIndex >= this.items?.length) {
        leftToRightDirection = false;
      }
      if (currentIndex <= 0) {
        leftToRightDirection = true;
      }
      this.viewport.scrollToIndex(currentIndex, 'smooth');
    }, 2500);

  }

}

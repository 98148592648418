import { Platform } from '@angular/cdk/platform';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private platform = inject(Platform);

  isBrowser: boolean;

  constructor() {
    this.isBrowser = this.platform.isBrowser;
  }

  scrollTop(element: HTMLElement) {
    if (this.isBrowser) {
      if ('parentIFrame' in window) {
        window.parentIFrame?.scrollToOffset(0, element?.getBoundingClientRect()?.top);
      } else {
        element?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    }
  }

}

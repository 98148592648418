import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Event, Language } from '../../../models';
import { creditCardAcceptableValidator, creditCardFormatValidator, expiryDateValidator } from '../../../validators';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TrimDirective } from '../../directives/trim.directive';
import { UppercaseSpace4Directive } from '../../directives/uppercase-space4.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    TrimDirective,
    UppercaseSpace4Directive,
    MatCheckbox,
    MatTooltip,
    LoaderComponent,
  ],
})
export class PaymentFormComponent implements OnDestroy {
  private formBuilder = inject(UntypedFormBuilder);
  private translocoService = inject(TranslocoService);

  creditCardAcceptableValidator = creditCardAcceptableValidator;
  creditCardFormatValidator = creditCardFormatValidator;
  expiryDateValidator = expiryDateValidator;

  isBrowser = false;
  currentLang: Language;

  paymentFormGroup: UntypedFormGroup = this.formBuilder.group({});
  @Input() public event: Event;
  @Input() public isFreeProcess = false;
  @Input() public hideCommercialCheckbox = false;

  transactionType = 'buying';
  cardType: 'visa' | 'mastercard' | 'amex';

  // backend response status
  isSavingData = false;
  formError = undefined;
  private langSub: Subscription;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
    this.isBrowser = isPlatformBrowser(platformId);
    /* init form */
    this.buildForm();
  }

  buildForm() {
    this.paymentFormGroup = this.formBuilder.group({
      cardNumber: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('[0-9 ]*'),
        Validators.minLength(13),
        Validators.maxLength(19),
      ]),
      expiryDate: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('(0[1-9]|1[0-2])/?[0-9]{2}'),
        Validators.maxLength(5),
      ]),
      cvv: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
        Validators.pattern('[0-9]{3,4}'),
      ]),
      cgReelax: new UntypedFormControl('', [
        Validators.requiredTrue,
      ]),
      cgMangopay: new UntypedFormControl('', [
        Validators.requiredTrue,
      ]),
      commercialCommunication: new UntypedFormControl('', []),
    },
    {
      validators: [
        creditCardFormatValidator,
        creditCardAcceptableValidator,
        expiryDateValidator,
      ],
    });
  }

  public formatExpiryDate(event) {
    if (event?.inputType !== 'insertText') {
      return;
    }
    const value =  event.target.value;
    if (value[0] !== '0' && value[0] !== '1') {
      event.target.value = '0' + value + '/';
    }
    if (event.target && value.length === 2) {
      event.target.value = value  + '/';
    }
    if (event.data === '/') {
      event.target.value = event.target.value.slice(0, -1);
    }
  }

  getError(formControlName: string) {
    const control = this.paymentFormGroup.get(formControlName);
    const hasError = !!control && !!control.touched && !!control.errors;
    if (hasError) {
      return Object.keys(control.errors)[0];
    }
    return null;
  }

  onCardNumberChange(event) {
    this.checkCardValidity();
    this.updateCardBrand(event);
  }

  checkCardValidity() {
    // if there is a validCreditCardFormat error and we didn't add it to cardNumber control, then we do it
    if (!!this.paymentFormGroup.hasError('validCreditCardFormat') &&
    !this.paymentFormGroup.get('cardNumber').hasError('validCreditCardFormat')) {
      if (this.paymentFormGroup.get('cardNumber').errors) {
        this.paymentFormGroup.get('cardNumber').setErrors({
          ...this.paymentFormGroup.get('cardNumber').errors,
          validCreditCardFormat: true,
        });
        return;
      } else {
        this.paymentFormGroup.get('cardNumber').setErrors({
          validCreditCardFormat: true,
        });
        return;
      }
    } else if (!this.paymentFormGroup.hasError('validCreditCardFormat') &&
    !!this.paymentFormGroup.get('cardNumber').hasError('validCreditCardFormat')) {
      // if there is not any validCreditCardFormat error anymore and we still store it into control errors,
      // then we reset its errors
      this.paymentFormGroup.get('cardNumber').setErrors(null);
    }

    // if there is a validCreditCardAcceptable error and we didn't add it to cardNumber control, then we do it
    if (!!this.paymentFormGroup.hasError('validCreditCardAcceptable') &&
    !this.paymentFormGroup.get('cardNumber').hasError('validCreditCardAcceptable')) {
      if (this.paymentFormGroup.get('cardNumber').errors) {
        this.paymentFormGroup.get('cardNumber').setErrors({
          ...this.paymentFormGroup.get('cardNumber').errors,
          validCreditCardAcceptable: true,
        });
        return;
      } else {
        this.paymentFormGroup.get('cardNumber').setErrors({
          validCreditCardAcceptable: true,
        });
        return;
      }
    } else if (!this.paymentFormGroup.hasError('validCreditCardAcceptable') &&
    !!this.paymentFormGroup.get('cardNumber').hasError('validCreditCardAcceptable')) {
      // if there is not any validCreditCardAcceptable error anymore and we still store it into control errors,
      // then we reset its errors
      this.paymentFormGroup.get('cardNumber').setErrors(null);
    }
  }

  updateCardBrand(event) {
    const value = event.target.value;
    if (value.length > 0 && parseInt(value[0], 10) === 4) {
      this.cardType = 'visa';
    } else if (value.length > 1 && parseInt(value[0], 10) === 5
              && parseInt(value[1], 10) < 6
              && parseInt(value[1], 10) > 0) {
      this.cardType = 'mastercard';
    } else if (value.length > 1 && parseInt(value[0], 10) === 3
              && (parseInt(value[1], 10) === 4 || parseInt(value[1], 10) === 7)) {
      this.cardType = 'amex';
    } else {
      this.cardType = null;
    }
  }

  public onPaymentSubmit(): {
    commercialCommunication: boolean,
    cardData: {cardNumber: string; cardExpirationDate: string; cardCvx: string}
    } {
    this.paymentFormGroup.markAllAsTouched();
    if (!this.paymentFormGroup.valid && !this.isFreeProcess) {
      this.formError = 'invalidForm';
      return {commercialCommunication: null, cardData: null};
    }
    this.formError = undefined;

    const commercialCommunication = this.paymentFormGroup.value.commercialCommunication;

    // Card data collected from the user
    let cardData;
    if (!this.isFreeProcess) {
      const cardExpValue = this.paymentFormGroup.value.expiryDate;

      cardData = {
        cardNumber: this.paymentFormGroup.value.cardNumber?.replace(/\s/g, ''), // without spaces
        cardExpirationDate: cardExpValue.replace('/', ''),
        cardCvx: this.paymentFormGroup.value.cvv,
      };
    }
    return {commercialCommunication, cardData};

  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

}

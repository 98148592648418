import { inject } from '@angular/core';

import { catchError, of, switchMap } from 'rxjs';
import { TicketsService } from '../../services';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EventStore } from '../../stores';
import { Platform } from '@angular/cdk/platform';
import { PublicEventResolver } from './public-event.resolver';
import { WaitListResolver } from '../../platform/purchase/waitList.resolver';


export function AvailableTicketsResolver(route: ActivatedRouteSnapshot) {
  const platform = inject(Platform);
  const publicEventResolver = inject(PublicEventResolver);

  if (!platform.isBrowser) {
    return publicEventResolver.resolve(route).pipe(
      catchError((err) => {
        // eslint-disable-next-line no-console
        console.error('unable to retrieve incomming events ssr');
        return of(true);
      }),
    );
  }

  const waitListResolver = inject(WaitListResolver);
  const eventStore = inject(EventStore);
  const ticketsService = inject(TicketsService);

  return publicEventResolver.resolve(route).pipe(
    switchMap(() => waitListResolver.resolve()),
    switchMap(() => {
      const eventId = eventStore.getCurrentEvent()?.id;
      return ticketsService.areAvailableTickets(eventId);
    }),
    catchError((err) => {
      // eslint-disable-next-line no-console
      console.error('unable to retrieve incomming events');
      return of(true);
    }),
  );
}

import { Injectable, inject } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private storageService = inject(StorageService);

  private storageKey = '[user]';

  getUser() {
    return this.storageService.getItem<{id: number}>(this.storageKey);
  }

  setUser(user: {id: number}) {
    this.storageService.setItem(this.storageKey, user);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, first, map, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Currencies, MinCardDetails, WaitListPurchase } from '../models';
import { Utils } from '../shared/utils';
import { WaitListPurchaseStore } from '../stores';
import { EncryptionService } from './';

@Injectable({
  providedIn: 'root',
})
export class WaitListPurchaseService {
  private http = inject(HttpClient);
  private waitListPurchaseStore = inject(WaitListPurchaseStore);
  private encryptionService = inject(EncryptionService);

  private configUrl = environment.apiUrl;

  getLastCard(eventId: number, currency: Currencies, userId: number) {
    const hash = this.encryptionService.MD5EncryptURI(userId.toString());
    const url = `${this.configUrl}waitListPurchase/lastcard/${eventId}/${currency}/${hash}`;
    return this.http.get<MinCardDetails>(url);
  }

  deleteRegisteredCard(lastCardId: string, wlpId: number, eventId: number, currency: Currencies){
    const url = `${this.configUrl}waitListPurchase/deleteCard/${lastCardId}`;
    return this.http.put<any>(url, {lastCardId, wlpId, eventId, currency}).pipe(
      first(),
    );
  }

  getWLPurchases(eventId: number) {
    const url = `${this.configUrl}waitListPurchases/${eventId}`;
    return this.http.get<{wlPurchases: WaitListPurchase[]}>(url).pipe(
      map( (wlpO) => wlpO?.wlPurchases?.map( (wlp) => new WaitListPurchase(wlp)) ),
    );
  }

  refreshWaitlistPurchaseFromApi(eventId: number) {
    return this.getWLPurchases(eventId).pipe(
      tap( (autoPurchase) => {
        this.waitListPurchaseStore.loadWLPurchase(autoPurchase);
      }),
    );
  }

  checkWaitListPurchase(eventId) {
    const url = `${this.configUrl}waitListPurchase/available/${eventId}`;
    return this.http.get<{maxRegistrationsReached: boolean}>(url);
  }

  updateWLP(waitListPurchase: Partial<WaitListPurchase>) {
    const url = `${this.configUrl}purchase/w/update`;
    return this.http.put<WaitListPurchase[]>(url, {waitListPurchase});
  }
}

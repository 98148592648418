import { Component, OnInit, inject } from '@angular/core';
import { NgxDropzonePreviewComponent, NgxDropzoneModule } from 'ngx-dropzone';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-uploader-tiny-preview',
  template: `
    <ng-content select="ngx-dropzone-label"></ng-content>
    @if (removable) {
      <ngx-dropzone-remove-badge (click)="_remove($event)">
      </ngx-dropzone-remove-badge>
    }
    `,
  styleUrls: ['./uploader-tiny-preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: UploaderTinyPreviewComponent,
    },
  ],
  standalone: true,
  imports: [NgxDropzoneModule],
})
export class UploaderTinyPreviewComponent extends NgxDropzonePreviewComponent implements OnInit {

  constructor() {
    const sanitizer = inject(DomSanitizer);

    super(sanitizer);
  }

  ngOnInit() {
    if (!this.file) {
      // eslint-disable-next-line no-console
      console.error('No file to read. Please provide a file using the [file] Input property.');
      return;
    }
  }
}

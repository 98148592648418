import { Component, OnInit, Input, OnDestroy, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgClass, AsyncPipe } from '@angular/common';
import { PasswordCheckValidator, passwordMatchValidator } from '../../../validators';
import { AppService, AuthService, RouterService } from '../../../services';
import { Credentials, ProcessType, Role, UserProfile } from '../../../models';
import { environment } from '../../../../environments/environment';
import { EmailCheckValidator } from '../../../validators';
import { Utils } from '../../utils';
import { PasswordStrengthComponent } from '../password-strength/password-strength.component';
import { LetDirective } from '../../directives/let.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatHint, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TrimDirective } from '../../directives/trim.directive';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LoaderComponent } from '../loader/loader.component';
import { MatDivider } from '@angular/material/divider';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [
    LetDirective,
    TranslocoModule,
    NgClass,
    MatCard,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    TrimDirective,
    MatHint,
    MatError,
    MatIconButton,
    MatSuffix,
    MatIcon,
    LoaderComponent,
    MatButton,
    MatDivider,
    PasswordStrengthComponent,
    MatCheckbox,
    MatTooltip,
    RouterLink,
    AsyncPipe,
  ],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  appService = inject(AppService);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private routerService = inject(RouterService);
  private emailCheckValidator = inject(EmailCheckValidator);
  private PasswordCheckValidator = inject(PasswordCheckValidator);

  @Input() noHistory = false;
  @Input() specificProcess: ProcessType;
  environment = environment;
  passwordMatchValidator = passwordMatchValidator;
  @Input() public isLogin = true;
  user$: Observable<UserProfile | boolean>;
  isLoadingAuth = false;
  formError = '';
  serverErrors: string[] = [];
  isPasswordForgotten = false;
  isNewPasswordMailSent = false;
  email: string;
  hidePassword1 = true;
  hidePassword2 = true;
  hideConfirmPassword = true;
  isBirthday = false;

  @ViewChild(PasswordStrengthComponent) passwordStrengthComponent: PasswordStrengthComponent;

  public credentialsForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  private credentials: Credentials;
  private registeredCredentials: Credentials;
  private userSub: Subscription;

  userRole: string;
  userId: number;
  firstname: string;

  constructor() {
    const authService = this.authService;

    this.user$ = authService.getUser();
    this.userSub = this.user$.subscribe({
      next: (u: UserProfile) => {
        this.userRole = u ? u.role : undefined;
        this.userId = u ? u.id : undefined;
        this.firstname = u ? u.firstname : undefined;
        this.isBirthday = Utils.isBirthdayToday(u?.birthday);
        if (u?.role && u.role !== Role.Anon) {
          this.handleLoginRedirection();
        }
        if (u?.email) {
          this.email = u.email;
          this.credentialsForm?.get('email')?.setValue(u.email);
          this.registerForm?.get('email')?.setValue(u.email);
        }
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on constructor function on login-form component :', err);
      },
    });
    this.route.queryParams.subscribe((params) => {
      // if we don't have specificProcess queryParams,
      // we must not setting specificProcess to false to avoid overriding potential value from Input()
      if (params.isSellingProcess) {
        // legacy params was isSellingProcess so we maintain it for user clicking on old emails
        this.specificProcess = ProcessType.Selling;
      }
      if (params.specificProcess) {
        this.specificProcess = params.specificProcess;
      }
      if ((!!params.register && params.register === 'true') || !this.isLogin) {
        this.switchLogin();
      }
    });
  }

  getError(form: UntypedFormGroup, field: string) {
    const control = form.get(field);
    if (!!control && !!control.touched && !!control.errors) {
      return Object.keys(control.errors)[0];
    }
    return null;
  }

  ngOnInit() {
    this.credentialsForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.email ?? '', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [this.emailCheckValidator.validate.bind(this.emailCheckValidator)],
      }),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
    });
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.email ?? '', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [this.emailCheckValidator.validate.bind(this.emailCheckValidator)],
      }),
      password: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        asyncValidators: [this.PasswordCheckValidator.validate.bind(this.PasswordCheckValidator)],
      }),
      passwordConfirm: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      cgReelax: new UntypedFormControl('', [Validators.requiredTrue]),
    }, { validators: passwordMatchValidator });
  }

  ngOnDestroy() {
    this.authService.redirectUrl = null;
    this.userSub?.unsubscribe();
  }

  checkPasswordMatch() {
    // if there is a noMatch error and we didn't add it to passwordConfirm control, then we do it
    if (!!this.registerForm.hasError('noMatch') &&
    !this.registerForm.get('passwordConfirm').hasError('noMatch')) {
      if (this.registerForm.get('passwordConfirm').errors) {
        this.registerForm.get('passwordConfirm').setErrors({
          ...this.registerForm.get('passwordConfirm').errors,
          noMatch: true,
        });
      } else {
        this.registerForm.get('passwordConfirm').setErrors({
          noMatch: true,
        });
      }
    } else if (!this.registerForm.hasError('noMatch') &&
                !!this.registerForm.get('passwordConfirm').hasError('noMatch')) {
      // if there is not any noMatch error anymore and we still store it into control errors, then we reset its errors
      this.registerForm.get('passwordConfirm').setErrors(null);
    }
  }

  goPreviousPage() {
    if (this.routerService.getPreviousUrl()) {
      this.router.navigateByUrl(this.routerService.getPreviousUrl());
    }
  }

  public switchLogin() {
    if (this.isLogin) {
      this.registerForm?.get('email')?.setValue(this.credentialsForm?.get('email')?.value);
      this.registerForm?.get('password')?.setValue(this.credentialsForm?.get('password')?.value);
    } else {
      this.credentialsForm?.get('email')?.setValue(this.registerForm?.get('email')?.value);
      this.credentialsForm?.get('password')?.setValue(this.registerForm?.get('password')?.value);
    }
    this.isLogin = !this.isLogin;
  }

  public onForgetPasswordClick() {
    this.isPasswordForgotten = !this.isPasswordForgotten;
  }

  public goBackToLogin() {
    this.isLogin = true;
    this.isPasswordForgotten = false;
    this.isNewPasswordMailSent = false;
  }

  public onSubmitLogin() {
    if (!this.isPasswordForgotten) {
      if (!this.credentialsForm.valid) {
        this.formError = 'invalidForm';
        return;
      }
      this.formError = '';
      this.serverErrors = [];
      /* If user is submitting classic login form */
      this.isLoadingAuth = true;
      this.credentials = new Credentials(this.credentialsForm.value.email, this.credentialsForm.value.password);
      // eslint-disable-next-line max-len
      const login$ = this.userRole === 'anonymous' ? this.authService.loginFromAnon(this.credentials) : this.authService.login(this.credentials);
      login$
        .subscribe({
          next: () => {
            this.isLoadingAuth = false;
            this.serverErrors = [];
            if (!this.noHistory) {
              this.goPreviousPage();
            }
          },
          error: (err) => {
            // eslint-disable-next-line no-console
            console.log('Error 1 on onSubmitLogin function on login-form component : ', err);
            this.handleServerError(err);
            this.isLoadingAuth = false;
          },
        });
    } else {
      if (!this.credentialsForm.get('email').valid) {
        this.formError = 'invalidForm';
        return;
      }
      this.formError = '';
      this.serverErrors = [];
      /* If user forgot his password and is asking for a password regeneration link */
      if (this.credentialsForm.get('email').status === 'VALID') {
        this.email = this.credentialsForm.get('email').value;
        this.isNewPasswordMailSent = true;
        this.authService.sendNewPasswordMail(this.email, this.specificProcess).subscribe(() => {
          this.serverErrors = [];
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log('Error 2 on onSubmitLogin function on login-form component : ', err);
          this.serverErrors = ['serverError'];
        });
      }
    }
  }

  public onSubmitRegister() {
    this.isLoadingAuth = true;
    this.registerForm.markAllAsTouched();
    if (this.passwordStrengthComponent?.progressSecurisation < 80) {
      this.isLoadingAuth = false;
      this.formError = 'notStrongEnough';
      return;
    }
    if (!this.registerForm.valid) {
      this.isLoadingAuth = false;
      this.formError = 'invalidForm';
      return;
    }
    this.formError = '';
    this.serverErrors = [];
    this.registeredCredentials = new Credentials(this.registerForm.value.email, this.registerForm.value.password);
    // eslint-disable-next-line max-len
    let register$: Observable<UserProfile>;
    if (this.userRole === 'anonymous') {
      register$ = this.authService.registerFromAnon(this.registeredCredentials, this.userId, this.specificProcess);
    } else {
      register$ = this.authService.register(this.registeredCredentials, this.specificProcess);
    }
    register$.subscribe({
      next: () => {
        this.isLoadingAuth = false;
        this.serverErrors = [];
        if (!this.noHistory) {
          this.goPreviousPage();
        }
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on onSubmitRegister function on login-form component : ', err);
        this.handleServerError(err);
        this.isLoadingAuth = false;
      },
    });
  }

  public logout() {
    this.authService.logout();
  }

  public handleServerError(err) {
    if (err.error?.error === '999945') {
      this.serverErrors = ['emailBlocked', 'emailSendingError'];
    } else if (err.error?.error === '999966') {
      this.serverErrors = ['accountLocked', 'emailSendingError'];
    } else {
      this.serverErrors = ['serverError'];
    }
  }

  public handleLoginRedirection() {
    if (this.authService.redirectUrl) {
      let redirectUrl = '';
      let fragment = '';
      const anchorIndex = this.authService.redirectUrl.indexOf('#');
      if (anchorIndex > 0) {
        fragment = this.authService.redirectUrl.slice(anchorIndex + 1);
        redirectUrl = this.authService.redirectUrl.slice(0, anchorIndex);
      } else {
        redirectUrl = this.authService.redirectUrl;
      }
      this.router.navigate([redirectUrl], {fragment});
      this.authService.redirectUrl = null;
    }
  }

}

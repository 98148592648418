<div id="event" class="events">
  <div class="events__carousel">
    @if (isLoadingEvents) {
      <app-loader diameter="40" class="m-auto"/>
    }

    @if (!isLoadingEvents) {
      <app-carousel [items]="events">
        <ng-template let-item="item">
          <app-event-card [event]="item" class="events__carousel__item"/>
        </ng-template>
      </app-carousel>
    }
  </div>

</div>

<ng-container *transloco="let t">

  @if (!hideFooter) {
    <mat-toolbar class="footer">
      <div class="page-container">
        <div class="footer__main-content">
          <app-reelax-tickets-logo class="mt-3 mb-4"/>
          <div class="footer__main-content__column">
            <h3 class="title">{{ t('common.organizers') }}</h3>
            <a class="tab-link" routerLink="/pro" routerLinkActive="--active">{{ t('footer.offer') }}</a>
            <a class="tab-link" routerLink="/pro" fragment="partenaires">{{ t('footer.partners') }}</a>
          </div>
          <div class="footer__main-content__column">
            <h3 class="title">{{ t('common.spectators') }}</h3>
            <a class="tab-link" routerLink="/" routerLinkActive="--active" [routerLinkActiveOptions]="{ exact: true }">{{ t('footer.home') }}</a>
            <a class="tab-link" routerLink="/evenements" routerLinkActive="--active">{{ t('common.events') }}</a>
            <a class="tab-link" routerLink="/faq/acheteur" fragment="acheteur_recuperer-billets" routerLinkActive="--active" [routerLinkActiveOptions]="{exact: true}">{{ t('footer.ticketLost') }}</a>
            <a class="tab-link" routerLink="/faq" routerLinkActive="--active">{{ t('common.help') }}</a>
          </div>
          <div class="footer__main-content__column">
            <h3 class="title">Reelax Tickets</h3>
            <a class="tab-link" routerLink="/mentions-legales" routerLinkActive="--active" href="/mentions-legales" target="_blank">{{ t('footer.legal') }}</a>
            <a class="tab-link" href="https://reelax-tickets.com/assets/pdf/PP_reelax.pdf" target="_blank">{{ t('footer.privacy') }}</a>
            <a class="tab-link" href="https://reelax-tickets.com/assets/pdf/CGU_reelax.pdf?v=06012025" target="_blank">{{ t('footer.conditions') }}</a>
            <a class="tab-link" href="https://reelax-tickets.com/docs/api" target="_blank">{{ t('footer.apiDoc') }}</a>
            <div class="footer__main-content__column__social-media-links">
              <a class="tab-link" href="https://www.facebook.com/reelax.tickets/" target="_blank" matTooltip="Facebook" rel="noopener">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.124 96.123">
                  <title>Facebook</title>
                  <path fill="currentColor" d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 00-1.96 1.961v15.803a1.96 1.96 0 001.96 1.96H36.57v39.876a1.96 1.96 0 001.96 1.96h16.352a1.96 1.96 0 001.96-1.96V54.287h14.654a1.96 1.96 0 001.96-1.96l.006-15.803a1.963 1.963 0 00-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 001.959-1.96V1.98A1.96 1.96 0 0072.089.02z"/>
                </svg>
              </a>
              <a class="tab-link" href="https://www.linkedin.com/company/reelax-tickets/" target="_blank" matTooltip="LinkedIn" rel="noopener">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
                  <title>LinkedIn</title>
                  <path fill="currentColor" d="M459 0H51C22.95 0 0 22.95 0 51v408c0 28.05 22.95 51 51 51h408c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM153 433.5H76.5V204H153v229.5zm-38.25-272.85c-25.5 0-45.9-20.4-45.9-45.9s20.4-45.9 45.9-45.9 45.9 20.4 45.9 45.9-20.4 45.9-45.9 45.9zM433.5 433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25 17.851-38.25 38.25V433.5H204V204h76.5v30.6c12.75-20.4 40.8-35.7 63.75-35.7 48.45 0 89.25 40.8 89.25 89.25V433.5z"/>
                </svg>
              </a>
              <a class="tab-link" href="https://www.instagram.com/reelaxtickets/" target="_blank" matTooltip="Instagram" rel="noopener">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170">
                  <title>Instagram</title>
                  <path fill="currentColor" d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"/><path fill="currentColor" d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 00-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="footer__main-content__column">
            <div class="footer__main-content__column__support">
              <p class="footer__main-content__column__support__text">
                {{ t('footer.sponsors') }}
              </p>
              <div class="footer__main-content__column__support__logos">
                @for (partner of institutionPartners; track partner) {
                  <a [matTooltip]="partner.name" [href]="partner.website" target="_blank">
                    <img class="footer__main-content__column__support__logos__img" [src]="'assets/img/' + partner.imgUrl" [alt]="partner.name">
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="footer__signature">
          <app-language-selector [hideFlag]="true"/>
          <span class="footer__signature__made-in">
            <img class="footer__signature__made-in__flag" alt="france"
              src="assets/img/flag-france.svg">
              <img class="footer__signature__made-in__flag" alt="gwenn-ha-du"
                src="assets/img/gwenn-ha-du.svg">
                <span>
                  Graet e Breizh
                </span>
              </span>
              <span class="footer__signature__copyright">
                © Reelax Tickets {{currentYear}}
              </span>
            </div>
          </div>
        </mat-toolbar>
      }

      @if (hideFooter && addSecurityMargin) {
        <div class="footer-margin-bottom"></div>
      }

    </ng-container>
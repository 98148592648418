<div class="page-container" *transloco="let tav; read 'accountValidation'">

  @if (isLoadingVerifying) {
    <app-loader diameter="40" class="my-5 mx-auto"/>
  }

  <!-- if user is verified -->
  @if (!isLoadingVerifying && isUserVerified) {
    <div>
      <p class="text">
        @if (specificProcess === processType.UpdateEmail) {
          {{ tav('congratulationsAccountUpdated') }}
        } @else {
          {{ tav('congratulationsAccountVerified') }}
        }
        <br><br>
        @if (specificProcess === processType.Selling) {
          {{ tav('saleNotFinished') }}<br>
          {{ tav('goBackToPreviousTab') }}
        }
        @if (specificProcess === processType.WaitList) {
          {{ tav('waitlistRegistrationNotComplete') }}<br>
          {{ tav('goBackToPreviousTab') }}
        }
        @if (!specificProcess || specificProcess === processType.UpdateEmail) {
          {{ tav('youCanCloseThisTab') }}
        }
      </p>
      <div class="illustration" *appLet="(appService.settings.asObservable() | async) as setting">
        <object
          type="image/svg+xml"
          [data]="'assets/img/illustration/happy.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          class="illustration__img"
          >
          <param name="color" value="url(#reelax-gradient)">
        </object>
      </div>
    </div>
  }

  <!-- if user is not verified  NOT for account locked -->
  @if (!isLoadingVerifying && !isUserVerified && validationType !== 'account-unlock' ) {
    <div>
      @if (!isRegenerateDisabled) {
        <div>
          <p class="text">
            {{ tav('validationLinkExpired') }}
            <br><br>
            {{ tav('clickToGenerateNewLink') }}
          </p>
          <div class="buttons-group --row --centered">
            <button mat-button class="button --primary" (click)="onRegenerateClick()" type="button">{{ tav('generateANewLink') }}</button>
          </div>
        </div>
      }
      @if (isRegenerateDisabled && !errors) {
        <p class="text">
          {{ tav('newLinkSent') }}
          <br><br>
          {{ tav('checkYourEmails') }}
        </p>
      }
      <ng-container *transloco="let tfe; read 'formErrors'">
        @for (error of errors; track error) {
          <p [innerHTML]="tfe(error)" class="text"></p>
        }
      </ng-container>
    </div>
  }

  <!-- if user has account locked error -->
  @if (validationType === 'account-unlock' && !isLoadingVerifying) {
    <div>
      @if (!isUserVerified) {
        <div>
          <p class="text">
            {{ tav('validationLinkExpired') }}
          </p>
        </div>
      }
      <ng-container *transloco="let tfe; read 'formErrors'">
        @for (error of errors; track error) {
          <p [innerHTML]="tfe(error)" class="text"></p>
        }
      </ng-container>
    </div>
  }

</div>
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private http = inject(HttpClient);


  configUrl = environment.apiUrl;

  checkEmail(email) {
    const route = `${this.configUrl}checkEmail`;
    return this.http.post<{isValid: boolean}>(route, { email });
  }

  getSlowDeliveryDomains() {
    const route = `${this.configUrl}r/s/slowDeliveryDomains`;
    return this.http.get<string[]>(route).pipe(first());
  }
}

import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { WaitListStore } from '../../../stores';
import { Event, WaitListConfig, WaitListPurchase } from '../../../models';
import { Utils } from '../../utils';
import { Subscription } from 'rxjs';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { NgPlural, NgPluralCase, KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-waitlist-summary',
  templateUrl: './waitlist-summary.component.html',
  styleUrls: ['./waitlist-summary.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    NgPlural,
    NgPluralCase,
    KeyValuePipe,
  ],
})
export class WaitlistSummaryComponent implements OnInit, OnDestroy {
  waitListStore = inject(WaitListStore);
  private translocoService = inject(TranslocoService);

  @Input() public event: Event;
  @Input() public waitListPurchase: WaitListPurchase;

  public waitListConfig: WaitListConfig;
  public askedAutoPurchaseNumber: number;
  public totalPurchasedTickets: number;
  private timeUnitTranslations;
  private translationSub: Subscription;

  public options: { allOrNone: boolean; sameCategoryOnly: boolean; } = {
    allOrNone: false,
    sameCategoryOnly: false,
  };
  public numberOfOptions: number = 0;

  ngOnInit(): void {
    this.translationSub = this.translocoService.selectTranslateObject('timeUnit.medium')
      .subscribe((translations) => {
        this.timeUnitTranslations = translations;
      });

    this.waitListConfig = this.event?.WaitListConfig;

    Object.keys(this.options).forEach((option) => {
      this.options[option] = this.waitListPurchase?.[option];
    });

    this.numberOfOptions = Object.values(this.options)?.filter((n) => n)?.length;
  }

  getAskedAutoPurchaseNumber() {
    return Math.min(
      (this.waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity ?? 0),
      (this.waitListPurchase?.prepaidTickets ?? 0),
    );
  }

  getManuallyPurchasedTickets(): number {
    return this.getTotalPurchasedTickets() - (this.waitListPurchase?.purchasedTickets ?? 0);
  }

  getTotalPurchasedTickets() {
    return Math.max(
      (this.waitListStore.getEntries(this.event.id)?.reduce((acc, val) => acc + (val.purchasedTicket ?? 0), 0) ?? 0),
      (this.waitListPurchase?.purchasedTickets ?? 0),
    );
  }

  msToTime(ms: number): string {
    return Utils.msToTime(ms, this.timeUnitTranslations);
  }

  ngOnDestroy(): void {
    this.translationSub?.unsubscribe();
  }
}

import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';

interface LetContext {
  $implicit;
  appLet;
}

@Directive({
  selector: '[appLet]',
  standalone: true,
})
export class LetDirective {
  private viewContainer = inject(ViewContainerRef);
  private templateRef = inject<TemplateRef<LetContext>>(TemplateRef);


  @Input()
  set appLet(value) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, {
      $implicit: value,
      appLet: value,
    });
  }

}

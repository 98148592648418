// eslint-disable-next-line max-classes-per-file
import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { catchError, of, retry } from 'rxjs';
import { captureException } from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);


  getTranslation(lang: string) {
    // eslint-disable-next-line no-undef
    let version = process.env.i18n_VERSION;
    // eslint-disable-next-line no-undef
    const dashbordVersion = process.env.i18n_VERSION_dashboard;
    if (lang.startsWith('dashboard')) {
      version = dashbordVersion;
    }
    const debug = false; // set as true to update translations on page reload
    let debugParam = '';
    if (debug) {
      debugParam = `&t=${(new Date()).getTime()}`;
    }
    version = encodeURIComponent(version);
    return this.http.get<Translation>(`${environment.domain}assets/i18n/${lang}.json?v=${version}${debugParam}`).pipe(
      // retry 2 times on error
      retry(2),
      catchError( (err) => {
        captureException(err);
        // default values
        return of({
          language: {
            locale: 'fr',
          },
        });
      }),
    );
  }
}

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot(),
  ],
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en-US', 'fr-FR', 'es-ES'],
        defaultLang: 'fr-FR',
        fallbackLang: 'fr-FR',
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}

import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { EventStore, WaitListStore } from '../../../stores';
import { Event, EventStatus, Language } from '../../../models';
import { AppService, ColorService, NotificationService } from '../../../services';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { NgClass, NgPlural, NgPluralCase } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-sale-purchase',
  templateUrl: './sale-purchase.component.html',
  styleUrls: ['./sale-purchase.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatIcon,
    RouterLink,
    MatButton,
    NgClass,
    NgPlural,
    NgPluralCase,
    MatCard,
    MatCardContent,
  ],
})
export class SalePurchaseComponent implements OnInit, OnDestroy {
  private eventStore = inject(EventStore);
  private colorService = inject(ColorService);
  private notificationService = inject(NotificationService);
  private translocoService = inject(TranslocoService);
  private waitListStore = inject(WaitListStore);
  private route = inject(ActivatedRoute);
  appService = inject(AppService);

  @Input() widget: boolean = false;
  public isLoading = false;
  public event: Event;
  public EventStatus = EventStatus;
  public currentLang: Language;

  public atLeastOneSellActivated = false;
  public atLeastOnePurchaseActivated = false;
  public ticketsAvailable = false;

  private eventSub: Subscription;
  private langSub: Subscription;

  constructor() {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
    this.ticketsAvailable = this.route.snapshot.data.availableTickets;
  }

  ngOnInit() {
    this.isLoading= true;
    this.eventSub = this.eventStore.currentEventId$.pipe(
      tap( () => {
        this.event = this.eventStore.getCurrentEvent();
        if (this.event.color) {
          this.colorService.setEventColor(this.event);
        }
        this.atLeastOneSellActivated = !!this.event.TicketingPlatforms.find((tp) => !tp.saleDisabled);
        this.atLeastOnePurchaseActivated = !!this.event.TicketingPlatforms.find((tp) => !tp.purchaseDisabled);

        if (!this.appService.settings.value?.isWidget) {
          this.notificationService.activity(this.event.totalTicketSold, this.event.id);
        }
        this.isLoading = false;
      }),
    ).subscribe({
      next: () => {},
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on getEvent in sale / purchase : ', err);
        this.isLoading = false;
      },
    });
  }

  get registeredInWaitList() {
    return this.waitListStore.getEntries(this.event.id).filter((e) => (e.id && e.activated && e.noChanges)).length <= 0;
  }

  ngOnDestroy() {
    this.eventSub?.unsubscribe();
    this.langSub?.unsubscribe();
  }

}

import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

import { forkJoin, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AuthService, WaitListService } from '../../services';
import { EventStore, WaitListStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class WaitListResolver  {
  private waitListService = inject(WaitListService);
  private waitListStore = inject(WaitListStore);
  private eventStore = inject(EventStore);
  private authService = inject(AuthService);

  private isBrowser = false;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  resolve() {
    if (!this.isBrowser) {
      return forkJoin({
        api: of([]),
        browser: of([]),
      }).pipe(
        tap( () => {
          this.waitListStore.loadEntries([]);
        }),
      );
    }
    return this.authService.getUser().pipe(
      first(),
      switchMap((u) => this.waitListService.refreshWaitlistFromApi(
        this.eventStore.getCurrentEvent().id,
        u,
        this.eventStore.getCurrentEvent().Categories,
      )),
    );
  }

}

@if (this.memorisedValues?.customTicketAnswers) {
  <div class="form custom-text-color">
    @for (field of customTicketFields | keyvalue; track field) {
      @if (field.value.type === 'string') {
        <app-text-form-field
          class="form__field --large"
          [form]="formGroup"
          [controlName]="field.key"
          [formModel]="memorisedValues.customTicketAnswers"
          [validators]="[ field.value.isRequired ? 'required' : '']"
      [options]="{
        label: getName(field.value.name),
        trim: true
      }"
        />
      }
      @if (field.value.type === 'number') {
        <app-numeric-form-field
          class="form__field --large"
          [form]="formGroup"
          [controlName]="field.key"
          [formModel]="memorisedValues.customTicketAnswers"
          [validators]="[ field.value.isRequired ? 'required' : '']"
      [options]="{
        label: getName(field.value.name),
      }"
        />
      }
      @if (field.value.type === 'boolean') {
        <app-checkbox-form-field
          class="form__field mb-2"
          [form]="formGroup"
          [controlName]="field.key"
          [formModel]="memorisedValues.customTicketAnswers"
          [validators]="[ field.value.isRequired ? 'requiredTrue' : '']"
        [options]="{
          label: getName(field.value.name),
          required: field.value.isRequired,
        }"
          >
        </app-checkbox-form-field>
      }
      @if (field.value.type === 'date') {
        <app-date-form-field
          [controlName]="field.key"
          [form]="formGroup"
          [formModel]="memorisedValues.customTicketAnswers"
          [validators]="[ field.value.isRequired ? 'required' : '']"
        [options]="{
          label: getName(field.value.name),
        }"
          >
        </app-date-form-field>
      }
    }
  </div>
}

<ng-container *transloco="let tav; read 'accountValidation'">
  @if (!!form.controls['password'].value) {
    <span class="mb-2 d-flex --vertical-centered">{{ tav('pwdStrength')}}
      @if (progressSecurisation <= 39) {
        <span class="reelax-red fw-600">{{ tav('weak')}}</span>
      } @else {
        @if (progressSecurisation <= 79 && progressSecurisation > 39) {
          <span class="reelax-orange fw-600">{{ tav('medium')}}</span>
        } @else {
          <span class="reelax-green fw-600">{{ tav('strong')}}</span>
        }
      }
      <button type="button" mat-icon-button matSuffix (click)="openDialog()">
        <mat-icon>info</mat-icon>
      </button>
    </span>
    <mat-progress-bar
      mode="determinate"
      [value]="progressSecurisation"
      class="mb-3"
      [ngClass]="{
        '--red': progressSecurisation <= 39,
        '--orange': progressSecurisation <= 79 && progressSecurisation > 39,
        '--green': progressSecurisation > 79,
      }"
    />
  }
</ng-container>

import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-alert-message-dialog',
  templateUrl: './alert-message-dialog.component.html',
  styleUrls: ['./alert-message-dialog.component.scss'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class AlertMessageDialogComponent implements OnInit {
  dialogRef = inject<MatDialogRef<AlertMessageDialogComponent>>(MatDialogRef);
  data: {
    message: string,
    button: string,
    duration: number,
  } = inject(MAT_DIALOG_DATA);


  private isBrowser = false;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser && this.data.duration) {
      setTimeout(() => {
        this.dialogRef.close();
      }, this.data.duration);
    }
  }

}

import { Component, inject } from '@angular/core';
import { SEOService } from '../../services';
import { LoginFormComponent } from '../../shared/components/login-form/login-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [LoginFormComponent],
})
export class LoginComponent {
  private seoService = inject(SEOService);


  constructor() {
    this.seoService.updateTitle('Connexion');
  }

}

import { Injectable, OnDestroy, inject } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContactInfo, Language, Event } from '../models';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ContactService implements OnDestroy {
  private http = inject(HttpClient);
  private translocoService = inject(TranslocoService);

  configUrl = environment.apiUrl;
  currentLang = Language.frFR;
  private langSub: Subscription;

  constructor() {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  sendMessage(contactInfo: ContactInfo): Observable<any> {
    const route = `${this.configUrl}contact`;
    return this.http.post(route, { ...contactInfo, language: this.currentLang }).pipe(
      catchError((error) => this.handleContactError(error)),
      map((res) => res),
    );
  }

  sendMessageInvalidTicket(
    contactInfo: ContactInfo,
    filesToDownload: {fileName: string, bucketId: string}[],
    ticketingPlatformIds: string[],
    event: Event,
  ): Observable<any> {
    const route = `${this.configUrl}contactInvalidTicket`;
    return this.http.post(route, {
      ...contactInfo,
      language: this.currentLang,
      filesToDownload,
      ticketingPlatformIds,
      eventId: event.id,
      eventName: event.name,
    })
      .pipe(
        catchError( (error) => this.handleContactError(error)),
      );
  }

  sendMessageInvalidTicketReresale(
    contactInfo: ContactInfo,
    ticketingPlatformIds: string[],
    event: Event,
  ) {
    const route = `${this.configUrl}contactInvalidTicketReresale`;
    return this.http.post(route, {
      ...contactInfo,
      language: this.currentLang,
      ticketingPlatformIds,
      eventId: event.id,
      eventName: event.name,
    }).pipe(
      catchError((error) => this.handleContactError(error)),
      map((res) => res),
    );
  }

  private handleContactError(error: HttpErrorResponse) {
    if (error && error.status > 199 && error.status < 300 ) {
      // eslint-disable-next-line no-console
      console.log('mail well sent !');
      return of(true);
    } else {
      // eslint-disable-next-line no-console
      console.log('Error on handleContactError function into contact service :', error);
      throw error;
    }
  }

  ngOnDestroy() {
    this.langSub?.unsubscribe();
  }
}

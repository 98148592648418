import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PurchaseInfo, Ticket } from '../models';
import { StorageService } from '../services';

@Injectable({providedIn: 'root'})
export class PurchaseInfosStore {
  private storageService = inject(StorageService);

  private readonly purchaseInfos = new BehaviorSubject<{[ticketId: number]: PurchaseInfo}>({});
  private readonly purchaseTickets = new BehaviorSubject<Ticket[]>([]);

  readonly purchaseInfos$ = this.purchaseInfos.asObservable();
  readonly purchaseTickets$ = this.purchaseTickets.asObservable();

  public storageKey = 'purchaseInfos';

  getPurchaseInfos(): {[ticketId: number]: PurchaseInfo} {
    return this.purchaseInfos.getValue();
  }

  updatePurchaseInfos(purchaseInfos: {[ticketId: number]: PurchaseInfo}) {
    this.purchaseInfos.next(purchaseInfos);
    this.storageService.setItem(this.storageKey, this.purchaseInfos.getValue());
  }

  cleanPurchaseInfos() {
    this.purchaseInfos.next([]);
    this.storageService.removeItem(this.storageKey);
  }

  loadPurchaseInfos(purchaseInfos: {[ticketId: number]: PurchaseInfo}) {
    this.purchaseInfos.next(purchaseInfos);
  }

  getPurchaseTickets(): Ticket[] {
    return this.purchaseTickets.getValue();
  }

  loadPurchaseTickets(tickets: Ticket[]) {
    this.purchaseTickets.next(tickets);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ProcessType, UserProfile } from '../../../models';
import { AuthService, EmailService, UserService } from '../../../services';
import { TranslocoModule } from '@ngneat/transloco';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { LowerCasePipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatCard,
    MatCardContent,
    MatIcon,
    LowerCasePipe,
    DatePipe,
  ],
})
export class EmailValidationComponent implements OnInit {
  private authService = inject(AuthService);
  userService = inject(UserService);
  private emailService = inject(EmailService);

  @Output() logOut = new EventEmitter();
  @Input() currentProcess: ProcessType;
  @Input() user: UserProfile;
  processType = ProcessType;
  public slowDeliveryDomains: string[];

  ngOnInit(): void {
    this.emailService.getSlowDeliveryDomains().subscribe((domains) => {
      this.slowDeliveryDomains = domains ?? [];
    });
  }

  onResendMail() {
    this.userService.regenerateToken(
      this.user?.UserAccount?.id,
      this.user?.UserAccount?.validationToken,
      this.currentProcess,
    ).subscribe({
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on onResendMail function on sale-step-third component :', err);
      },
    });
  }

  reloadUser() {
    this.authService.forceReload();
  }

  changeAccount() {
    this.logOut.emit(true);
    this.authService.logout();
  }

}

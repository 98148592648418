import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, timer, of, BehaviorSubject, NEVER } from 'rxjs';
import { catchError, switchMap, shareReplay, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserBankingInfo, Bucket, UserProfile, Currencies, ProcessType } from '../models';
import { AuthService, EncryptionService } from './';
import { RxjsUtils } from '../shared/utils/rxjs';
import { Router } from '@angular/router';

const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 4;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  private router = inject(Router);
  private encryptionService = inject(EncryptionService);

  private configUrl = environment.apiUrl;

  private bucketsCache$: Observable<Bucket[]>;
  private reloadBuckets$ = new Subject<void>();

  public lastValidationEmailSent: Date;

  public registeredUserBankingInfo: UserBankingInfo;

  validateUser(token: string, specificProcess: ProcessType, userAccountId?: number): Observable<boolean> {
    const route = `${this.configUrl}accountValidation/${userAccountId}`;
    return this.http.put<boolean>(route, {token, specificProcess})
      .pipe(
        catchError(this.handleUserError),
      );
  }

  regenerateToken(userAccountId: number, token: string, specificProcess = null): Observable<any> {
    const route = `${this.configUrl}resetToken/${userAccountId}`;
    return this.http.put<boolean>(route, {token, specificProcess})
      .pipe(
        tap( () => {
          this.lastValidationEmailSent = new Date();
        }),
        catchError(this.handleUserError),
      );
  }

  updateUserInfo(user: Partial<UserProfile>, options?: Record<string, boolean>): Observable<UserProfile> {
    const route = `${this.configUrl}users`;
    return this.http.put<UserProfile>(route, {
      ...user.serialize(),
      ...options,
    })
      .pipe(
        catchError(this.handleUserError),
        tap( () => this.authService.forceReload()),
      );
  }

  updateLanguage(lang: string): Observable<UserProfile> {
    const route = `${this.configUrl}users/language`;
    return this.http.put<UserProfile>(route, {lang})
      .pipe(
        catchError(this.handleUserError),
        tap( () => this.authService.forceReload()),
      );
  }

  getUserInfo(): Observable<UserProfile> {
    const route = `${this.configUrl}users`;
    return this.http.get<UserProfile>(route)
      .pipe(
        RxjsUtils.backToLoginIf401(this.router, this.authService),
        catchError(this.handleUserError),
      );
  }

  getWallets(userId: number) {
    if (!userId) { // if user not authenticated generate random id for hash above existing user.id
      userId = (1000000000) * (1 + Math.random());
    }
    const hash = encodeURIComponent(this.encryptionService.MD5EncryptURI(userId.toString()));
    const route = `${this.configUrl}userWallets/${hash}`;
    return this.http.get<Array<{Amount: number, Currency: Currencies}>>(route)
      .pipe(
        catchError(this.handleUserError),
      );
  }

  getUserBankingInfo() {
    const route = `${this.configUrl}userBankingInfo`;
    return this.http.get<UserBankingInfo & {RefusedReasonFlags: string[], RefusedReasonType: string}>(route)
      .pipe(
        catchError(this.handleUserError),
        tap((ub) => this.registeredUserBankingInfo = ub),
      );
  }

  deleteBankingAccount() {
    const route = `${this.configUrl}userBankingInfo`;
    return this.http.delete<UserBankingInfo>(route).pipe(
      map((ub) => new UserBankingInfo(ub)),
    );
  }

  /**
   * @deprecated
   */
  getUserKycStatus(userId, mangokycId): Observable<any> {
    const route = `${this.configUrl}users/${userId}/kyc/${mangokycId}`;
    return this.http.get(route)
      .pipe(
        catchError(this.handleUserError),
      );
  }

  createMangoBankAccount(userBanking, bankingAddress): Observable<any> {
    const route = `${this.configUrl}createMangoBankAccount`;
    return this.http.post<UserBankingInfo>(route, {userBanking, bankingAddress} )
      .pipe(
        catchError(this.handleUserError),
        tap(() => this.registeredUserBankingInfo = userBanking),
        map((ub) => new UserBankingInfo(ub)),
      );
  }

  unlockAccount(token: string, userAccountId: number) {
    const route = `${this.configUrl}account-unlock`;
    return this.http.put<boolean>(route, {token, userAccountId})
      .pipe(
        catchError(this.handleUserError),
      );
  }

  private handleUserError(error: HttpErrorResponse) {
    // 'no wallet for this account'
    if (!!error.error?.ResultCode && [999980].includes(error.error?.ResultCode)) {
      return of();
    }
    // eslint-disable-next-line no-console
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    return throwError(() => error);
  }

  // Buckets managment
  public getBuckets() {
    if (!this.bucketsCache$ ) {
      // Set up timer that ticks every X milliseconds when toggled on
      const toggle$ = new BehaviorSubject(true);
      const timer$ = toggle$.pipe(
        switchMap( (paused) => paused ? timer(0, REFRESH_INTERVAL) : NEVER),
      );

      // For each timer tick make an http request to fetch new data
      // We use shareReplay(X) to multicast the cache so that all
      // subscribers share one underlying source and don't re-create
      // the source over and over again. We use takeUntil to complete
      // this stream when the user forces an update.
      this.bucketsCache$ = this.reloadBuckets$.asObservable().pipe(
        switchMap(() => this.authService.getUser()),
        tap( () => {
          toggle$.next(true);
        }),
        switchMap(() => timer$),
        switchMap(() => this.requestBuckets() ),
        tap( (b) => {
          if (b.length <= 0) {
            toggle$.next(false);
          }
        }),
        shareReplay(CACHE_SIZE),
      );
    }

    return this.bucketsCache$;
  }

  forceReloadBuckets() {
    // Calling next will reload buckets manually
    this.reloadBuckets$.next();
  }

  // Helper method to actually fetch the bucket
  private requestBuckets(): Observable<Bucket[]> {
    return this.authService.getUser().pipe(
      switchMap( (u: UserProfile) => {
        if (!u) {
          this.bucketsCache$ = null;
          return of([]);
        }
        const route = `${this.configUrl}users/${u.id}/bucket`;
        return this.http.get<Bucket[]>(route);
      }),
      map((bucketsData) => bucketsData.map((b) => new Bucket({...b, eventName: b.Event?.name }))),
    );
  }

}


<div class="small-container" *transloco="let tav; read 'accountValidation'">

  @if (isLoadingVerifying) {
    <app-loader diameter="40" class="my-5 mx-auto"/>
  }

  <!-- if user is verified -->
  @if (!isLoadingVerifying && isTokenValid) {
    <div>
      <!-- If token is valid, then the user can choose his new password -->
      @if (!isPasswordUpdated) {
        <div class="login center">
          <h1 class="subtitle --center mb-3">{{ tav('passwordReset') }}</h1>
          <mat-card class="mt-4 mb-5">
            <mat-card-content>
              <form class="form --centered" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()">
                <mat-form-field class="form__field mb-2">
                  <mat-label>{{tav('password')}}</mat-label>
                  <input formControlName="password" matInput (input)="checkPasswordMatch()" [type]="hidePassword ? 'password' : 'text'" required>
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  @if (getError('password')) {
                    <mat-error>{{ 'formErrors.' + getError('password') | transloco }}</mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="form__field mb-2">
                  <mat-label>{{tav('passwordConfirmation')}}</mat-label>
                  <input formControlName="passwordConfirm" matInput (input)="checkPasswordMatch()" [type]="hideConfirmPassword ? 'password' : 'text'" required>
                  <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                    <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  @if (getError('passwordConfirm')) {
                    <mat-error>{{ 'formErrors.' + getError('passwordConfirm') | transloco }}</mat-error>
                  }
                </mat-form-field>
                <app-password-strength class="form__field full-width text-left mb-3" [form]="resetPasswordForm"/>
                @if (isLoadingPasswordUpdate) {
                  <app-loader diameter="40" class="m-auto"/>
                }
                @if (!!error) {
                  <p class="full-width error center">{{ 'formErrors.' + error | transloco }}</p>
                }
                <div class="buttons-group --row --centered">
                  <button mat-button class="button --small --full-width --primary" type="submit">{{ tav('resetMyPassword') }}</button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      }
      <!-- Once password has well been updated -->
      @if (isPasswordUpdated) {
        <div>
          <p class="text">
            {{ tav('congratulationsPasswordUpdated') }}
          </p>
          @if (specificProcess === processType.Selling) {
            <p class="text">
              {{ tav('saleNotFinished') }}<br>
              {{ tav('goBackToPreviousTab') }}
            </p>
          }
          @if (specificProcess === processType.WaitList) {
            <p class="text">
              {{ tav('waitlistRegistrationNotComplete') }}<br>
              {{ tav('goBackToPreviousTab') }}
            </p>
          }
          @if (!specificProcess) {
            <p class="text">
              {{ tav('youCanNowLogIn') }}
            </p>
            <div class="buttons-group --row --centered">
              <a routerLink="/connexion">
                <button mat-button class="button --primary" type="button">
                  {{ 'button.logIn' | transloco }}
                </button>
              </a>
            </div>
          }
          <div class="illustration" *appLet="(appService.settings.asObservable() | async) as setting">
            <object
              type="image/svg+xml"
              [data]="'assets/img/illustration/happy.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
              class="illustration__img"
              >
              <param name="color" value="url(#reelax-gradient)">
            </object>
          </div>
        </div>
      }
    </div>
  }

  <!-- if token is not valid anymore -->
  @if (!isLoadingVerifying && !isTokenValid) {
    <h1 class="title --page mb-3">{{ tav('passwordReset') }}</h1>
    <mat-card class="mt-4 mb-5">
      <mat-card-content>
        @if (!isRegenerateDisabled) {
          <div>
            <p class="text">
              {{ tav('validationLinkExpired') }}
              <br><br>
              {{ tav('clickToGenerateNewLink') }}
            </p>
            <div class="buttons-group --row --centered">
              <button mat-button class="button --primary" (click)="onRegenerateClick()" type="button">{{ tav('generateANewLink') }}</button>
            </div>
          </div>
        }
        @if (isRegenerateDisabled) {
          <p class="text">
            {{ tav('newLinkSent') }}
            <br><br>
            {{ tav('checkYourEmails') }}
          </p>
        }
      </mat-card-content>
    </mat-card>
  }

</div>
<div class="page-container margins" [appHelp]="['buyer']">

  @if (isCheckingPayment) {
    <div>
      <p class="loading-text subtitle --center" *transloco="let tpp; read 'purchase.processing'">
        {{ tpp('transactionProcessingPleaseWait') }}
      </p>
      <app-loader diameter="60" class="m-auto my-4"/>
      @if (this.fact1) {
        <div>
          <p class="loading-text--quote">
            <strong>{{ this.fact1.type }} :</strong>
          </p>
          <p class="loading-text--quote">
            {{ this.fact1.fact }}
          </p>
        </div>
      }
      @if (this.fact2) {
        <div>
          <p class="loading-text--quote">
            <strong>{{ this.fact2.type }} :</strong>
          </p>
          <p class="loading-text--quote">
            {{ this.fact2.fact }}
          </p>
        </div>
      }
    </div>
  }

  <ng-container *transloco="let tpe; read 'purchase.error'">
    @if (!!payementFailed || !!errorMessage) {
      <div class="error-card__column error">
        <mat-icon class="reelax-red">error</mat-icon>
        @if (!!payementFailed) {
          <div class="mb-4">
            <p class="error-card__subtitle mb-4 fs-20 fw-700">{{ tpe('paymentFailure') }}</p>
            <p class="fs-16">{{ tpe('bankAccountNotDebited') }}</p>
          </div>
        }
        @if (!!errorMessage) {
          <div class="mb-4">
            <p [ngClass]="{'error-card__subtitle mb-4 fs-20 fw-700' : !payementFailed}">{{ errorMessage }}</p>
            <p>{{ errorInfo }}</p>
          </div>
        }
        @if (!!payementFailed) {
          <div class="buttons-group --row --centered">
            @if (ticketId) {
              <button mat-button class="button --primary" [routerLink]="['/evenement/', eventId, 'achat', ticketId, '3']">
                {{ tpe('retryMyPayment') }}
              </button>
            }
            @if (wlPurchaseId) {
              <button mat-button class="button --primary" [routerLink]="['/evenement/', eventId, 'achat', 'attente', '3']">
                {{ tpe('retryMyPayment') }}
              </button>
            }
          </div>
        }
      </div>
    }
  </ng-container>

  @if (waiting) {
    <div class="buttons-group --row --centered">
      @if (ticketId) {
        <button mat-button class="button --primary" (click)="doPaymentCheck()">
          {{ 'button.update' | transloco }}
        </button>
      }
    </div>
  }

  @if (payementFailed || errorMessage) {
    <p class="text --normal" [innerHTML]="'formErrors.errorPersist' | transloco"></p>
  }

</div>
<ng-container *transloco="let ttp; read 'ticketPrice'">

  <app-event-custom-message [message]="event?.EventPersonalization?.priceSellingPage"/>

  <ng-container *transloco="let tsf; read 'sale.formTitle'">
    <div class="small-container px-0">
      <div class="icon-text mt-4 mb-3">
        <mat-icon class="custom-event">confirmation_number</mat-icon>
        <span class="icon-text__text fw-700 confirmation-page__text">{{ tsf('sellSummary') }}</span>
      </div>

      <mat-card class="custom-border-left my-3 summary__card">
        <mat-card-content>
          <p class="summary__card__bold-text">{{ event.name }} </p>
          @if (category?.CategoriesGroup?.name) {
            <p class="summary__card__text">{{ category?.CategoriesGroup?.name }}</p>
          }
          <p class="summary__card__text">{{ category?.name }}</p>
          @if (saleTickets?.length > 0) {
            <p class="summary__card__text">{{ saleTickets.length + ' ' + (saleTickets.length > 1 ? tsf('tickets') : tsf('ticket'))}}</p>
          }
          @for (ticket of saleTickets; track ticket) {
            <p class="summary__card__text">
              @if (ticket.seatDetails) {
                @if (saleTickets.length > 1 && hasMatchingProperty(category.displayedSeatingInformation, ticket.seatDetails)) {
                  •
                }
                <app-seat-details
                  [seatDetails]="ticket.seatDetails"
                  [displayedSeatingInformation]="category.displayedSeatingInformation"
                />
              }
            </p>
          }
        </mat-card-content>
      </mat-card>
    </div>

    <div class="icon-text mt-4 mb-3">
      <mat-icon class="custom-event">sell</mat-icon>
      <span class="icon-text__text fw-700 confirmation-page__text">{{ tsf('pricePerTicket') }}</span>
    </div>
  </ng-container>

  @if (form && selectedPriceControl && platformPriceControl) {
    <div class="facing-cards">
      <div class="facing-cards__card">
        <p class="facing-cards__card__title mb-4">
          @if (!isSamePrice) {
            {{ ttp('howMuchDoYouWant') }}
          }
          @if (isSamePrice) {
            {{ ttp('whatYouWillReceive') }}
          }
        </p>
        <mat-form-field class="facing-cards__card__field" subscriptSizing="dynamic">
          <input
            data-cy="price-input"
            type="number"
            step="0.01"
            matInput
            [formControl]="selectedPriceControl"
            (input)="onSelectedPriceInput($event)"
            (change)="onSelectedPriceChange($event)"
            (onWheel)="$event?.preventDefault()"
            >
          <span matSuffix class="m-1">{{ category?.currencySymbol }}</span>
          <mat-error class="facing-cards__card__field__error">
            {{ ttp('choosePriceBetween', {
            minimalSalePrice: (category?.minimalSalePrice/100 | number: '1.2-2':'fr'),
            maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'),
            currencySymbol: category?.currencySymbol
            }) }}
          </mat-error>
        </mat-form-field>
        @if (form.controls.selectedPrice.untouched || form.controls.selectedPrice.valid) {
          <div class="facing-cards__card__after-price fw-400 mt-2">
            <p>{{ ttp('pricePerTicket') }}</p>
            <div class="d-flex --centered --vertical-centered">
              <span>{{ ttp('maxPrice', {
                maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'),
                currencySymbol: category?.currencySymbol
              }) }}</span>
              <button
                mat-button
                type="button"
                class="button --x-small --outlined-custom-event ml-3"
                [class.--outlined-custom-event]="isMaxPrice()"
                [class.--outlined-primary]="!isMaxPrice()"
                (pointerdown)="onMaxPrice()"
                >
                {{ 'button.max' | transloco }}
              </button>
            </div>
          </div>
        }
        <span class="facing-cards__card__info-details-link">
          <mat-icon class="hidden-icon">confirmation_number</mat-icon>
        </span>
        @if (!isSamePrice) {
          <p class="facing-cards__card__information mt-3">
            {{ ttp('priceRestricted') }}
            @if (isPartner) {
              <span>
                {{ ttp('byTheOrganizer') }}
              </span>
            }
            {{ ttp('betweenPrice', {
            minimalSalePrice: (category?.minimalSalePrice/100 | number: '1.2-2':'fr'),
            maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'),
            currencySymbol: category?.currencySymbol
            }) }}
          </p>
        }
        @if (isSamePrice) {
          <p class="facing-cards__card__information mt-3">
            {{ ttp('priceRestricted') }}
            @if (isPartner) {
              <span>
                {{ ttp('byTheOrganizer') }}
              </span>
            }
            {{ ttp('byTheOrganizer', { price: category?.minimalSalePrice/100 | number: '1.2-2':'fr' }) }}
          </p>
        }
        <div class="facing-cards__card__icon" *appLet="(appService.settings.asObservable() | async) as setting">
          <object
            type="image/svg+xml"
            [data]="'assets/img/illustration/secure-shield.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
            class="facing-cards__card__icon__img"
            >
            <param name="color" [value]="event && event.color ? event.color : '#242425'">
          </object>
        </div>
      </div>
      <div class="facing-cards__card">
        <p class="facing-cards__card__title mb-4">
          {{ ttp('displayedPrice') }}
        </p>
        <mat-form-field class="facing-cards__card__field" subscriptSizing="dynamic">
          <input type="number" step="0.01" matInput [formControl]="platformPriceControl" (input)="onPlatformPriceInput($event)" (change)="onPlatformPriceChange($event)" (onWheel)="$event?.preventDefault()">
          <span matSuffix class="m-1">{{ category?.currencySymbol }}</span>
        </mat-form-field>
        <div class="facing-cards__card__after-price fw-400 mt-2">
          <p>{{ ttp('pricePerTicket') }}</p>
        </div>
        <a class="facing-cards__card__info-details-link" (click)="openDialog()">
          <span>{{ ttp('howIsThisPriceCalculated') }}</span>
          <mat-icon>info</mat-icon>
        </a>
        @if (!isSamePrice && minimalPlatformPrice && maximalPlatformPrice) {
          <p class="facing-cards__card__information">
            @if (minimalPlatformPrice !== maximalPlatformPrice) {
              {{ ttp('pricesGoesFromTo', {
              minimalPlatformPrice: (minimalPlatformPrice | number: '1.2-2':'fr'),
              maximalPlatformPrice: (maximalPlatformPrice | number: '1.2-2':'fr'),
              currencySymbol: category?.currencySymbol
              }) }}
            } @else {
              {{ ttp('priceIsAt', {
              price: (maximalPlatformPrice | number: '1.2-2':'fr'),
              currencySymbol: category?.currencySymbol
              }) }}
            }
          </p>
        }
      </div>
    </div>
  }
</ng-container>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { forkJoin, map, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Article, BlogHome, Interview, ListEntry, SingleEntry } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {
  private http = inject(HttpClient);
  private translocoService = inject(TranslocoService);

  private url = environment.strapiUrl + 'api/';

  private getActiveLang() {
    return this.translocoService.getActiveLang();
  }

  private callStrapiApi<T>(url, params) {
    return this.translocoService.langChanges$.pipe(
      switchMap( (lang) => this.http.get<ListEntry<Article>>(`${this.url}${url}`, {
        params: {
          ...params,
          locale: lang,
        },
      })),
    );
  }

  getArticles(options: {offset?: number, limit?: number, contains: string}) {
    let params = new HttpParams({ fromObject: {
      'populate': ['illustration'],
      'pagination[start]': options?.offset ?? 0,
      'pagination[limit]': options?.limit,
      'sort[0]': 'createdAt:desc',
      'locale': this.getActiveLang(),
    }});
    if (options?.contains) {
      params = params.set('filters[title][$containsi]', options.contains);
    }
    return this.http.get<ListEntry<Article>>(`${this.url}blog-articles`, {params}).pipe(
      map( (l) => ({news: this.parseAnyResponse(l), meta: l.meta})),
    );
  }

  getInterviews() {
    return this.callStrapiApi<ListEntry<Interview>>('blog-interviews', {
      'populate': ['illustration'],
      'sort[0]': 'createdAt:desc',
    }).pipe(
      map( (l) => this.parseAnyResponse(l)),
    );
  }

  getHeadline() {
    return this.callStrapiApi<SingleEntry<BlogHome>>('blog-headline', {
      populate: [
        'title',
        'title_cta',
        'headline_1_interview.illustration',
        'headline_1_article.illustration',
        'headline_1_news.illustration',
      ],
    }).pipe(
      map( (data) => this.parseAnyResponse(data)),
    );
  }

  getNews(options: {offset?: number, limit?: number, contains: string}) {
    let params = new HttpParams({ fromObject: {
      'populate': ['illustration'],
      'pagination[start]': options?.offset ?? 0,
      'pagination[limit]': options?.limit,
      'locale': this.getActiveLang(),
      'sort[0]': 'createdAt:desc',
    }});
    if (options?.contains) {
      params = params.set('filters[title][$containsi]', options.contains);
    }
    return this.http.get<ListEntry<Article>>(`${this.url}blog-news`, {params}).pipe(
      map( (l) => ({news: this.parseAnyResponse(l), meta: l.meta})),
    );
  }

  getArticle(slug: string) {
    const params = {
      'populate[0]': ['illustration'],
      'populate[1]': ['localizations'],
      'filters[slug][$eq]': slug,
      'locale': 'all',
    };
    return forkJoin({
      article: this.http.get<ListEntry<Article>>(`${this.url}blog-articles`, { params }),
      news: this.http.get<ListEntry<Article>>(`${this.url}blog-news`, { params }),
    }).pipe(
      map( (v) => v.article.data.length > 0 ? v.article : v.news),
      map( (v) => this.parseAnyResponse(v)[0]),
      switchMap( (article: Article) => this.translocoService.langChanges$.pipe(
        map( (lang) => {
          if (article.locale === lang) {
            return article;
          } else {
            return article.localizations.find( (a) => a.locale === lang) ?? article;
          }
        }),
      )),
    );
  }

  private parseSingleResponse<T extends object>(entry: SingleEntry<T> | T) {
    if (!('data' in entry)) {
      return entry;
    }
    return entry.data?.attributes;
  }

  private parseArrayResponse<T>(entry: ListEntry<T> | T[]) {
    if (!('data' in entry)) {
      return entry;
    }
    if ( entry.data === null ) {
      return null;
    }
    return entry.data.map((e) => e.attributes);
  }

  public parseMarkdown(markdown: string) {
    const linkRegex = /(!\[[^\]]*\]\()(\/uploads)([^)]+)(\))/gm;
    return markdown?.replace(linkRegex, '$1/strapi$2$3)') ?? '';
  }

  private prefixUrl<T>(strapiObject: T & {url?: string, formats?: {}}) {
    if (strapiObject?.url && !strapiObject.url.startsWith('/strapi')) {
      strapiObject.url = '/strapi' + strapiObject.url;
    }
    if (strapiObject?.formats) {
      Object.keys( strapiObject.formats).forEach( (key) => {
        if (strapiObject.formats[key].url && !strapiObject.formats[key].url.startsWith('/strapi')) {
          strapiObject.formats[key].url = '/strapi' + strapiObject.formats[key].url;
        }
      });
    }
    return strapiObject;
  }

  private parseAnyResponse<T extends object>(entry: ListEntry<T> | SingleEntry<T> | T | T[]) {
    if (entry === null || !entry) {
      return entry;
    } else if (typeof entry === 'object' && !('data' in entry)) {
      if (Array.isArray(entry)) {
        entry.map( (e) => Object.entries(e).forEach( ([key, val]) => {
          if (typeof val === 'object' && val !== null && 'data' in val) {
            if (Array.isArray(val.data)) {
              e[key] = this.parseAnyResponse(this.parseArrayResponse(val));
            } else {
              e[key] = this.parseAnyResponse(this.parseSingleResponse(val));
            }
          }
        }));
      }
      Object.entries(entry).forEach( ([key, val]) => {
        if (typeof val === 'object' && val !== null && 'data' in val) {
          if (Array.isArray(val.data)) {
            entry[key] = this.parseAnyResponse(this.parseArrayResponse(val));
          } else {
            entry[key] = this.parseAnyResponse(this.parseSingleResponse(val));
          }
        }
      });
      if (Object.prototype.hasOwnProperty.call(entry, 'url')) {
        return this.prefixUrl(entry);
      }
      return entry;
    }

    if (Array.isArray((entry as {data}).data)) {
      return this.parseAnyResponse(this.parseArrayResponse<T>(entry as ListEntry<T>));
    }
    return  this.parseAnyResponse(this.parseSingleResponse<T>(entry as SingleEntry<T>));
  }

}

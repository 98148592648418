import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PurchaseInfosStore, WaitListStore } from '../../../stores';
import { FieldType } from '../../../models';
import { MatFormField, MatError, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TrimDirective } from '../../directives/trim.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-custom-buyer-fields',
  templateUrl: './custom-buyer-fields.component.html',
  styleUrls: ['./custom-buyer-fields.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    TrimDirective,
    MatError,
    MatCheckbox,
    TranslocoModule,
    MatLabel,
    MatSelect,
    MatOption,
  ],
})
export class CustomBuyerFieldsComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private fb = inject(UntypedFormBuilder);
  private purchaseInfosStore = inject(PurchaseInfosStore);
  private waitListStore = inject(WaitListStore);

  @Input() JSONfields;
  @Input() eventId;
  customBuyerFields: Array<{name: string, isRequired: boolean, type: string}>;
  formGroup: UntypedFormGroup;
  formError: string;
  ticketId: number;
  memorisedValues;

  availableDays: Array<number>;
  availableMonths: Array<string> = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  availableYears = [...Array(80).keys()].map((n) => (new Date().getFullYear()) - n);

  constructor() {
    this.formGroup = this.fb.group({});
  }

  ngOnInit(): void {
    this.ticketId = this.route.parent?.snapshot?.params?.ticketId;
    const purchaseInfos = this.purchaseInfosStore.getPurchaseInfos();
    const waitListEntry = this.waitListStore.getEntries(this.eventId)
      ?.filter((x) => x.activated && x.customWaitListFields)?.[0] ?? undefined ;
    if (this.ticketId && purchaseInfos?.[this.ticketId]) {
      this.memorisedValues = purchaseInfos[this.ticketId].customBuyerFields;
    } else if (waitListEntry) {
      this.memorisedValues = waitListEntry.customWaitListFields;
    }
    this.customBuyerFields = this.JSONfields;
    this.customBuyerFields.forEach((field) => {
      const validators = field.isRequired ? [Validators.required] : [];
      if (field.type === 'date') {
        const memorizedDate = new Date(this.memorisedValues?.[field.name]);
        this.formGroup.addControl(field.name, new FormGroup({
          day: new FormControl(memorizedDate.getDate(), validators),
          month: new FormControl(memorizedDate.getMonth(), validators),
          year: new FormControl(memorizedDate.getFullYear(), validators),
        }));
        this.updateAvailableDays(null, null);
      } else {
        this.formGroup.addControl(field.name, new UntypedFormControl(
          this.memorisedValues?.[field.name] ?? '',
          validators,
        ));
      }
    });
  }

  onEdit() {
    this.formError = '';
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.formError = 'invalidForm';
      return new Error(this.formError);
    }
    return {
      ...this.formGroup.value,
      ...this.customBuyerFields.filter( (f) => f.type === FieldType.date).reduce( (dateFields, field) => {
        const dateGroup = this.formGroup.get(field.name) as FormGroup;
        dateFields[field.name] = new Date(
          dateGroup.controls.year.value,
          dateGroup.controls.month.value - 1,
          dateGroup.controls.day.value,
        );
        return dateFields;
      }, {}),
    };
  }

  getError(formControlName: string) {
    const control = this.formGroup.get(formControlName);
    if (!!control && !!control.errors) {
      return Object.keys(this.formGroup.get(formControlName).errors)[0];
    }
    return null;
  }

  onDateChange(formControlName: string) {
    const control = this.formGroup.get(formControlName) as FormGroup;
    const month = control.controls.month.value;
    const year = control.controls.year.value;
    this.updateAvailableDays(month, year);
  }

  updateAvailableDays(month, year?) {
    /* If month is unset, january, march, may, july, august, october, december : 31 days displayed */
    if (!month || [0, 1, 3, 5, 7, 8, 10, 12].includes(month)) {
      // produces array with value from 0 to 31 and then remove the 0
      this.availableDays = [...Array(32).keys()].slice(1);
    } else if ([4, 6, 9, 11].includes(month)) {
      /* If month is april, june, september, november : 30 days displayed */
      this.availableDays = [...Array(31).keys()].slice(1);
    } else if (month === 2) {
      /* If month is february, either 28 or 29 days */
      /* If year undefined or leap : 29 days displayed for february */
      if (!year || year % 4 === 0) {
        this.availableDays = [...Array(30).keys()].slice(1);
      } else {
        /* If year is not leap : 28 days displayed for february */
        this.availableDays = [...Array(29).keys()].slice(1);
      }
    }
  }

}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { of, throwError, Subject, ReplaySubject, Subscription } from 'rxjs';
import { first, map, switchMap, tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService, IframeService, UserService } from '.';
import { Bucket, ReelaxError, Ticket, UserProfile, MangoError, Language, Currencies } from '../models';
import { isPlatformBrowser } from '@angular/common';
import { PurchaseInfosStore } from '../stores';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService implements OnDestroy {
  private http = inject(HttpClient);
  private router = inject(Router);
  private userService = inject(UserService);
  private authService = inject(AuthService);
  private translocoService = inject(TranslocoService);
  private purchaseInfosStore = inject(PurchaseInfosStore);
  private iframeService = inject(IframeService);
  private platform = inject(Platform);

  public bucketSubject = new Subject<Bucket>();
  public currentError = new ReplaySubject<ReelaxError>();
  private langSub: Subscription;

  private configUrl = environment.apiUrl;
  private currentLang = Language.frFR;

  private isBrowser = false;
  private isApple = false;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
    this.isApple = this.platform.SAFARI || this.platform.IOS;
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  // method to fetch the ticket
  public getTicketByUrlOnce(eventUrl, ticketId: number, token?: string, waitlistToken?: string) {
    let route = `${this.configUrl}e/n/${eventUrl}/tickets/${ticketId}`;

    const params = Object.entries({token, waitlistToken})
      .filter( ([k, p]) => !!p)
      .map( ([k, p]) => `${k}=${p}`).join('&');

    if (params) {
      route += `?${params}`;
    }
    return this.http.get<Ticket>(route).pipe(
      map((response) => new Ticket(response)),
    );
  }

  // Get card preregistration data from backend
  public getCardRegistrationObject(currency: Currencies) {
    return this.authService.getUser().pipe(
      first(),
      switchMap( (user: UserProfile) => {
        if (!currency) {
          throw new Error('getCardRegistrationObject missing currency');
        }
        const route = `${this.configUrl}purchase/r/${user.mangoPayId}/cardregistrations?currency=${currency}`;
        return this.http.post<any>(route, {});
      }),
      catchError(this.handleTicketsError),
    );
  }

  public addToBucket(ticketId: number, eventId: number, categoryId: number, token?: string) {
    if (!ticketId || !eventId) {
      return of(null);
    }
    const iframeApple = this.iframeService.isIframed() && this.isApple;
    const user = { email: 'anon', language: this.currentLang, password: 'anon'};
    let queryParams = '';
    if (token) {
      queryParams = `token=${encodeURIComponent(token)}`;
    }
    const route = `${this.configUrl}bucket?${queryParams}`;
    return this.http.post(route, {
      ticketId,
      eventId,
      categoryId,
      ...user,
    }, {
      headers: iframeApple ? new HttpHeaders({'X-RT-apple': 'true'}) : undefined,
    }).pipe(
      catchError((val) => of('In addToBucket function, I caught: ', val)),
      tap((b: [Bucket, Ticket]) => {
        this.bucketSubject.next(b[0]);
        this.authService.forceReload();
        this.userService.forceReloadBuckets();
      }),
    );
  }

  removeBucketItem(bucket: Bucket) {
    const route = `${this.configUrl}bucket/remove/${bucket.ticketId}`;
    return this.http.delete<any>(route)
      .pipe(
        catchError((val) => of('In removeBucketItem function, I caught: ', val)),
        tap(() => {
          this.bucketSubject.next(undefined);
          this.authService.forceReload();
          this.userService.forceReloadBuckets();
          if (this.router.url.includes(`/achat/${bucket.ticketId}`)) {
            this.router.navigateByUrl(this.router.url.split(bucket.ticketId.toString())[0].replace(/\/$/, ''));
          }
        }),
      );
  }

  public checkTicketPaymentStatus(ticketId: number, paymentToken: string, transactionId?: string ) {
    const BrowserInfo = this.fillBrowserInfo();
    const params = new HttpParams({
      fromObject: {
        BrowserInfo: JSON.stringify(BrowserInfo),
      },
    });

    const route = `${this.configUrl}purchase/t/${ticketId}/payment-status/${encodeURIComponent(paymentToken)}/${transactionId}`;
    return this.http.get<{
      SecureModeNeeded?: string,
      SecureModeRedirectURL?: string,
      SecureModeReturnURL?: string,
      status?: string,
      ResultMessage?: string,
      ResultCode?: number,
      Status?: string,
      TransactionData?: { SaleRecord: {
        eventId: number,
        status: string,
        ticketId: number,
        ticketToken: string,
      }},
      TransactionStatus?: string,
    }>(route, {params}).pipe(
      first(),
    );
  }

  public checkWaitlistPaymentStatus(waitListPurchaseId: number, paymentToken: string, transactionId?: string ) {
    const BrowserInfo = this.fillBrowserInfo();
    const params = new HttpParams({
      fromObject: {
        BrowserInfo: JSON.stringify(BrowserInfo),
      },
    });

    const route = `${this.configUrl}purchase/w/${waitListPurchaseId}/payment-status/${encodeURIComponent(paymentToken)}/${transactionId}`;
    return this.http.get(route, {params}).pipe(
      catchError((val) => of('In checkWaitlistPaymentStatus function, I caught: ', val)),
      first(),
    );
  }

  private fillBrowserInfo() {
    if (!this.isBrowser) {
      return {};
    }
    return {
      AcceptHeader: '', // string, filled later with backend req object
      ColorDepth: window.screen.colorDepth, // int
      JavaEnabled: false, // boolean
      JavascriptEnabled: true , // boolean
      Language: navigator.language, // string
      ScreenHeight: screen.height, // int
      ScreenWidth: screen.width, // int
      TimeZoneOffset: (new Date()).getTimezoneOffset()?.toString(), // string
      UserAgent: navigator.userAgent, // string
    };
  }

  public sendWaitlistPurchaseData(cardregistration, cardInfo, eventId, wlpOptions, isChangingCard: boolean) {
    const BrowserInfo = this.fillBrowserInfo();
    const {formData, options} = this.buildPurchaseRequest(cardregistration, cardInfo);

    return this.http.post<string>(
      cardregistration.CardRegistrationURL,
      formData,
      options,
    ).pipe(
      tap( (RegistrationData) => {
        if (typeof RegistrationData === 'string' || RegistrationData instanceof String) {
          const errorIndex = RegistrationData.indexOf('errorCode=');
          if ( errorIndex >= 0 ) {
            const errorCode = RegistrationData.slice(errorIndex + 10) as string;
            throw new HttpErrorResponse({error: new MangoError(parseInt(errorCode, 10) ??  errorCode, 'Card obj error', 'Error')});
          }
        }
      }),
      switchMap((RegistrationData) => {
        const route = `${this.configUrl}purchase/w/${eventId}`;
        return this.http.post<any>(route, {
          RegistrationData,
          Id: cardregistration.Id,
          BrowserInfo,
          wlpOptions,
          isChangingCard,
        });
      }),
      catchError(this.handleTicketsError),
    );
  }

  public sendPurchaseData(cardregistration, cardInfo, purchaseData, eventId) {
    // we fill purchaseData so the new name will be printed onto new ticket (it is required for prod!)
    const purchaseInfos = this.purchaseInfosStore.getPurchaseInfos();
    purchaseData.tickets.forEach((t) => {
      const currentPurchaseInfos = purchaseInfos[t.id];
      t.newFirstName = currentPurchaseInfos?.ticketHolders?.newFirstName;
      t.newLastName = currentPurchaseInfos?.ticketHolders?.newLastName;
      t.customBuyerFields = currentPurchaseInfos?.customBuyerFields;
      t.customTicketAnswers = currentPurchaseInfos?.customTicketAnswers;
    });

    const BrowserInfo = this.fillBrowserInfo();
    const {formData, options} = this.buildPurchaseRequest(cardregistration, cardInfo);

    return this.http.post<string>(
      cardregistration.CardRegistrationURL,
      formData,
      options,
    ).pipe(
      tap( (RegistrationData) => {
        if (typeof RegistrationData === 'string' || RegistrationData instanceof String) {
          const errorIndex = RegistrationData.indexOf('errorCode=');
          if ( errorIndex >= 0 ) {
            const errorCode = RegistrationData.slice(errorIndex + 10) as string;
            throw new HttpErrorResponse({error: new MangoError(parseInt(errorCode, 10) ??  errorCode, 'Card obj error', 'Error')});
          }
        }
      }),
      switchMap((RegistrationData) => {
        const route = `${this.configUrl}purchase/p/${eventId}/${purchaseData.tickets[0].id}`;
        return this.http.post<any>(route, {
          RegistrationData,
          Id: cardregistration.Id,
          purchaseData,
          BrowserInfo,
        });
      }),
      catchError( (err) => {
        // eslint-disable-next-line no-console
        console.log('purchase registration err', err);
        throw err;
      }),
      tap( () => this.userService.forceReloadBuckets()),
      catchError(this.handleTicketsError),
    );
  }

  public sendFreePurchaseData(purchaseData, eventId) {
    const route = `${this.configUrl}purchase/f/${eventId}/${purchaseData.tickets[0].id}`;
    const purchaseInfos = this.purchaseInfosStore.getPurchaseInfos();
    purchaseData.tickets.forEach((t) => {
      const currentPurchaseInfos = purchaseInfos[t.id];
      t.newFirstName = currentPurchaseInfos?.ticketHolders?.newFirstName;
      t.newLastName = currentPurchaseInfos?.ticketHolders?.newLastName;
      t.customBuyerFields = currentPurchaseInfos?.customBuyerFields;
      t.customTicketAnswers = currentPurchaseInfos?.customTicketAnswers;
    });
    return this.http.post<any>(route, {
      purchaseData,
    }).pipe(
      tap( () => this.userService.forceReloadBuckets()),
      catchError(this.handleTicketsError),
    );
  }

  private buildPurchaseRequest(cardregistration, cardInfo): {formData: string, options} {
    const formData = new URLSearchParams();
    formData.set('data', cardregistration.PreregistrationData);
    formData.set('accessKeyRef', cardregistration.AccessKey);
    formData.set('cardNumber', cardInfo.cardNumber);
    formData.set('cardExpirationDate', cardInfo.cardExpirationDate);
    formData.set('cardCvx', cardInfo.cardCvx);
    return {
      formData: formData.toString(),
      options: {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',
        }),
        responseType: 'text',
      },
    };
  }

  private handleTicketsError(error: HttpErrorResponse) {
    // TODO refacto, we should not throw all errors
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error.error.message);
    } else if (error.error && (error.error.name === 'MangoError' || error.error.name === 'ReelaxError')) {
      // eslint-disable-next-line no-console
      console.log('mango error');
      return throwError(() => error.error);
    } else if (Object.prototype.hasOwnProperty.call(error, 'error')) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(
        `Backend returned code ${error.status}, ` +
        'body was:');
      // eslint-disable-next-line no-console
      console.error(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(
        `Backend returned code ${error.status}, ` +
        'body was:');
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // return an observable with a user-facing error message
    return throwError( () => new Error('Something bad happened into tickets service; please try again later.'));
  }

  ngOnDestroy() {
    this.langSub?.unsubscribe();
  }
}

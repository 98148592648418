import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, ElementRef, Input, inject } from '@angular/core';

@Directive({
  selector: '[appScroll]',
  standalone: true,
})
export class ScrollDirective implements AfterViewInit {
  private el = inject(ElementRef);
  private platform = inject(Platform);

  @Input() set appScroll(scroll: boolean | '') {
    this.scroll = !!scroll;
    this.scrollToElement();
  }
  private scroll = true;
  private isBrowser = false;

  constructor() {
    this.isBrowser = this.platform.isBrowser;
  }

  ngAfterViewInit() {
    this.scrollToElement();
  }

  private scrollToElement() {
    if (this.isBrowser && this.scroll) {
      setTimeout(() => {
        this.el?.nativeElement?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
      }, (100));
    }
  }

}

import { Injectable, inject } from '@angular/core';
import { map, first, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CategoriesGroup, Event, EventCustomFaq, EventSearchOptions, TicketingPlatform } from '../models';
import { Observable, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private http = inject(HttpClient);

  public lastEventId: number;
  public lastEventUrl: string;
  public ticketingPlatforms$ = new Subject<TicketingPlatform[]>();

  public getSomeEvents = new Subject<{
    limit: number,
    offset: number,
  } & EventSearchOptions>();

  configUrl = environment.apiUrl;

  updateLastEventId(eventId: number) {
    this.lastEventId = eventId;
  }

  getPaginatedEvents() {
    const route = `${this.configUrl}events`;
    return this.getSomeEvents.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // ignore new term if same as previous term
      distinctUntilChanged(),
      // switch to new search observable each time the term changes
      switchMap( ({limit, offset, searchQuery, sort}) => {
        const params = new HttpParams({
          fromObject: {
            limit: limit.toString(),
            offset: offset.toString(),
            searchQuery: encodeURIComponent(searchQuery?.toString()),
            sort,
          },
        });
        return this.http.get<{rows: Event[], count: number}>(route, {params});
      }),
      map( (res) => ({
        ...res,
        rows: res.rows.map( (e) => new Event(e)),
      })),
      catchError((err) => {
        // eslint-disable-next-line no-console
        console.log('getPaginatedEvents failed ', route, err);
        throw err;
      }),
    );
  }

  getIncommingEvents(limit = 10, offset = 0, searchQuery = '') {
    const route = `${this.configUrl}events`;
    const params = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offset: offset.toString(),
        searchQuery: searchQuery.toString(),
      },
    });
    return this.http.get<{rows: Event[], count: number}>(route, {params});
  }

  checkEvents() {
    const route = `${this.configUrl}checkEvents/`;
    return this.http.get<{route: string}>(route).pipe(
      first(),
    );
  }

  getEventIfAvailable(eventId?: number, eventUrl?: string) {
    let route = '';
    if (eventId && eventId > 0) {
      this.lastEventId = eventId;
      route = `${this.configUrl}events/${eventId}`;
    } else if (eventUrl && eventUrl !== '') {
      this.lastEventUrl = eventUrl;
      route = `${this.configUrl}e/n/${eventUrl}`;
    } else {
      throwError({message: 'getEventIfAvailable requires eventId or eventUrl'});
    }
    return this.http.get<Event>(route).pipe(
      map((res) => {
        this.ticketingPlatforms$.next(res.TicketingPlatforms);
        return res;
      }),
      first(),
    );
  }

  public getEventCustomFaq(eventId: number) {
    const route = `${this.configUrl}e/faq/${eventId}`;
    return this.http.get<EventCustomFaq>(route);
  }

  public getTicketingPlatforms(): Observable<TicketingPlatform[]> {
    return this.ticketingPlatforms$.asObservable();
  }

  public populateCategoriesGroup(event: Event, categoriesGroups: CategoriesGroup[]){
    const result = [
      new CategoriesGroup({
        id: 0,
        order: 0,
        name: undefined,
      }, event.Categories),
      ...event.CategoriesGroups.map((catGroup) => new CategoriesGroup({
        id: catGroup.id,
        order: catGroup.order,
        name: catGroup.name,
      }, event.Categories)),
    ];
    if (categoriesGroups[0]?.Categories?.length === 0) {
      categoriesGroups.shift();
    }
    result?.sort((a,b) => a?.order - b?.order);
    result?.forEach((cgGrp) => cgGrp.Categories?.sort((a,b) => a?.order - b?.order));
    return result;
  }

}

import { Component, inject } from '@angular/core';
import { press } from '../../../../assets/ressources/press';
import { Platform } from '@angular/cdk/platform';
import { CarouselScrollComponent } from '../carousel-scroll/carousel-scroll.component';
import { PressCardComponent } from '../carousel/press-card/press-card.component';

@Component({
  selector: 'app-all-press-articles-carousel',
  templateUrl: './all-press-articles-carousel.component.html',
  styleUrls: ['./all-press-articles-carousel.component.scss'],
  standalone: true,
  imports: [CarouselScrollComponent, PressCardComponent],
})
export class AllPressArticlesCarouselComponent {
  private platform = inject(Platform);

  press = press;

  // item size must be in px without unit
  public itemSize = '240';
  private isBrowser = false;

  constructor() {
    this.isBrowser = this.platform.isBrowser;
    if (this.isBrowser) {
      const mediaQuery = window.matchMedia('(min-width: 576px)');
      if (mediaQuery.matches) {
        this.itemSize = '300';
      }
    }
  }

}

import { Injectable, inject } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import SHA1 from 'crypto-js/sha1';
import HEX from 'crypto-js/enc-hex';
import WordArray from 'crypto-js/lib-typedarrays';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  private http = inject(HttpClient);


  configUrl = environment.apiUrl;

  private postFile<T>(filesToUpload: File[], endpoint) {
    const formData: FormData = new FormData();
    filesToUpload.forEach((f) => {
      formData.append(f.name, f, f.name);
    });
    return this.http.post<T>(endpoint, formData).pipe(
      catchError(this.handleError),
    );
  }

  public postKyc(filesToUpload: File[], userId): Observable<any> {
    const endpoint = `${this.configUrl}users/${userId}/kyc`;

    return this.postFile(filesToUpload, endpoint);
  }

  private getUploadUrl() {
    const endpoint = `${this.configUrl}users/getUploadUrl`;

    return this.http.get<{bucketId: string; uploadUrl: string; authorizationToken: string;}>(endpoint).pipe(
      first(),
    );
  }

  private callB2WithBackOff = async (url: RequestInfo, options: RequestInit) => {
    let delaySeconds = 1;
    const maxDelay = 64;

    const sleepSeconds = (s: number) => {
      const ms = s * 1000;
      return new Promise( (resolve) => setTimeout(resolve, ms));
    };
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await fetch(url, options);
      const status = response.status;
      if (status === 429 /* Too Many Requests*/) {
        await sleepSeconds(parseInt(response.headers['Retry-After'], 10));
        delaySeconds = 1; // reset 503 back-off
      } else if (status === 503 /* Service Unavailable*/) {
        if (maxDelay < delaySeconds) {
          // give up -- delay is too long
          throw new Error(`Backblaze get upload url too long ${delaySeconds}`);
        }
        await sleepSeconds(delaySeconds);
        delaySeconds = delaySeconds * 2;
      } else if (response.status === 400) {
        throw response;
      } else {
        return response;
      }
    }
  };

  public uploadFile(fileData: ArrayBuffer, fileName: string, eventName: string) {
    const sha1sum = SHA1(WordArray.create(fileData as any)).toString(HEX);

    return this.getUploadUrl().pipe(
      switchMap( (uploadAuth) => {
        const isoDate = new Date().toISOString();
        const fileExtension = fileName.split('.').at(-1);
        const uploadfileName = encodeURIComponent(`${environment.envName}/invalidTicket/${isoDate}-${eventName}.${fileExtension}`);
        const url = uploadAuth.uploadUrl + `?Authorization=${uploadAuth.authorizationToken}`;
        return from(this.callB2WithBackOff(url, {
          headers: {
            'Authorization': uploadAuth.authorizationToken,
            'Content-Type': 'b2/x-auto',
            'X-Bz-Content-Sha1': sha1sum,
            'X-Bz-File-Name': uploadfileName,
          },
          method: 'post',
          body: fileData,
        // eslint-disable-next-line arrow-body-style
        }).then((res) => {
          return res.json() as unknown as {
            bucketId: any;
            status;
            downloadUrl: string;
            fileName: string;
          };
        }));
      }),
    );
  }

  public postTicketTemplate(ticketTemplate: File, eventUrl: string) {
    const endpoint = `${this.configUrl}e/a/${eventUrl}/upload-ticket-template`;

    return this.postFile([ticketTemplate], endpoint) as Observable<{
      downloadUrl: string;
      fileName: string;
      id: number;
      token: string;
    }>;
  }

  public postImageUrl(imageUrl: string, eventUrl: string, fileName: string) {
    const endpoint = `${this.configUrl}e/a/${eventUrl}/upload-image-url`;
    return this.http.post<{
      downloadUrl: string,
      fileName: string,
      id: number,
      token: string,
    }>(endpoint, {imageUrl, fileName}).pipe(
      catchError( (err) => {
        // case imageUrl returns a 404 => no image to save
        if (err.status === 404) {
          return of(undefined);
        }
        throw err;
      }),
    );
  }

  public postImage(image: File, eventUrl: string): Observable<any> {
    const endpoint = `${this.configUrl}e/a/${eventUrl}/upload-image`;

    return this.postFile([image], endpoint);
  }

  public assignTicket(
    ticketPdf: File[],
    eventUrl: string,
    ticketId: number,
    newTicketNumber: string,
    mailToSend: {[subject: string]: boolean},
  ) {

    let endpoint = `${this.configUrl}e/a/${eventUrl}/deliverTicket/${ticketId}/${newTicketNumber}?`;
    Object.keys(mailToSend)?.forEach((key) => {
      if (mailToSend[key]) {
        endpoint += `${key}=true&`;
      }
    });

    return this.postFile(ticketPdf, endpoint);
  }

  public getTicketTemplateFullPath(templateUrl) {
    return `${this.configUrl}r/i/${templateUrl}`;
  }

  private handleError(error: HttpErrorResponse): Observable<unknown> {
    // todo no throw
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(
        `Backend returned code ${error.status}, ` +
        'body was:');
      // eslint-disable-next-line no-console
      console.error(error.error);
    }
    // return an observable with a user-facing error message
    // eslint-disable-next-line no-console
    console.log('Something bad happened into uploader service; please try again later.');
    throw error.error;
  }
}

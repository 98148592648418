import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OrganizersService } from '../../services';
import { Organizer } from '../../models';
import { OrganizerStore } from '../../stores';

// todo migrate to resolve function
// How to handle the currentEvent ? => user service ?

@Injectable({
  providedIn: 'root',
})
export class OrganizerResolver  {
  private router = inject(Router);
  private organizersService = inject(OrganizersService);
  private organizerStore = inject(OrganizerStore);

  private currentOrganizer: Organizer;

  resolve(route: ActivatedRouteSnapshot) {
    const organizerUrl = route.params.organizerUrl;
    if (this.currentOrganizer && (this.currentOrganizer.url === organizerUrl) ) {
      return of(this.currentOrganizer);
    }
    return this.organizersService.getOrganizerByUrl(organizerUrl).pipe(
      tap((o) => {
        this.currentOrganizer = o;

        this.organizerStore.loadOrganizers([this.currentOrganizer]);
        this.organizerStore.setCurrentOrganizer(this.currentOrganizer.id);
      }),
      catchError((err) => {
        // eslint-disable-next-line no-console
        console.error('organizer does not exist');
        this.router.navigate(['404']);
        return EMPTY;
      }),
    );
  }

}

import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { TranslocoService } from '@ngneat/transloco';
import { AlertMessageDialogComponent } from '../components/alert-message-dialog/alert-message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class RateLimitService {
  private dialog = inject(MatDialog);
  private translocoService = inject(TranslocoService);


  public userLimitedDialog(resetTime: Date) {
    const resetTimeString = new DatePipe(this.translocoService.translate('language.locale')).transform(resetTime, 'medium');

    this.dialog.open(AlertMessageDialogComponent, {
      data: {
        button: 'Ok',
        message: this.translocoService.translate('errors.tooManyRequest', {resetTimeString}),
      },
      scrollStrategy: new NoopScrollStrategy(), // allows to scroll with opened dialog
    });
  }

}

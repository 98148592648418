import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { ColorService } from '../../../services';
import { Language, LanguageMap } from '../../../models';
import { Utils } from '../../utils';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-event-custom-message',
  templateUrl: './event-custom-message.component.html',
  styleUrls: ['./event-custom-message.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatIcon,
    TranslocoModule,
  ],
})
export class EventCustomMessageComponent implements AfterViewInit {
  private colorService = inject(ColorService);

  @Input() public message?: LanguageMap;
  @Input() public translocoMessage?: string;
  // MatCard has not nativeElement so you have to fetch element reference
  @ViewChild('messageCard', { read: ElementRef }) messageCard: ElementRef;
  private isBrowser = false;
  public Language = Language;
  public languages = Object.values(this.Language);

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  hasOneTanslation() {
    return Utils.hasOneTanslation(this.message, this.Language);
  }

  ngAfterViewInit() {
    const backgroundColor = this.colorService.getEventColor(true);
    if (this.isBrowser && (this.hasOneTanslation() || this.translocoMessage)) {
      this.messageCard.nativeElement.style.backgroundColor = backgroundColor;
    }
  }

}

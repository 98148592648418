<mat-sidenav-container
  class="full-height"
  [ngClass]="appService.settings?.value.isWidget ? 'custom-background-color' : 'reelax-light-grey-bg'"
  >
  <mat-sidenav-content class="page">

    <div #header class="header">
      <app-header/>
    </div>

    <div class="content" [ngClass]="isWidget ? 'content-widget': 'content'">
      <router-outlet/>
      @if (loadingRouteModule) {
        <div class="loader">
          <app-loader diameter="120" class="loader__spinner"/>
        </div>
      }
    </div>

    <app-footer class="footer"/>
  </mat-sidenav-content>

</mat-sidenav-container>

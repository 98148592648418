import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

const stringify = (obj) => {
  let cache = [];
  const str = JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
};

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private isBrowser = false;
  private persistentKeys = ['[lang]', '[cookie]'];

  // save sessionStore : current tab only
  // save localStorage : browser storage
  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  private get localStorage() {
    try {
      if (this.localStorage) {
        return localStorage;
      }
      throw Error('undefined local storage');
    } catch (error) {
      return {
        getItem: () => '',
        setItem: () => undefined,
        removeItem: () => undefined,
        key: () => undefined,
        clear: () => undefined,
        length: undefined,
      } as Storage;
    }
  }

  getItem<T>(key: string): Promise<T> {
    if (!this.isBrowser) {
      return Promise.resolve(undefined);
    }
    const stringy = this.localStorage.getItem(key);
    let value;
    try {
      value = stringy ? JSON.parse(stringy) : undefined;
    } catch (error) { /* empty */ }
    return Promise.resolve(value);
  }

  setItem<T>(key: string, value: T): Promise<void> {
    if (!this.isBrowser) {
      return Promise.resolve(undefined);
    }
    const result = this.localStorage.setItem(key, stringify(value));
    return Promise.resolve(result);
  }

  removeItem(key: string): Promise<void> {
    if (!this.isBrowser) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(this.localStorage.removeItem(key));
  }

  removeAllItem(): Promise<void> {
    return Promise.all( this.persistentKeys.map((k) => this.getItem(k).then((v) => ([k, v]))) )
      .then( (toPersist) => {
        Promise.resolve(this.localStorage.clear())
          .then( () => Promise.all(toPersist.map( ([k, v]: [string, unknown]) => this.setItem(k, v))));
      });
  }
}

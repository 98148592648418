import { Injectable, inject } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class CookieStore {
  private storageService = inject(StorageService);

  private storageKey = '[cookie]';

  getCookie() {
    return this.storageService.getItem<boolean>(this.storageKey);
  }

  setCookie(displayed: boolean) {
    this.storageService.setItem(this.storageKey, displayed);
  }

}

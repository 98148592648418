
<div class="form --inline" *transloco="let t;">
  <mat-form-field class="mr-3 form__field --normal">
    <mat-label>{{ t('dashboard.data.salesChart.dataRange.quickSelect') }}</mat-label>
    <mat-select
      [formControl]="control"
      (selectionChange)="setPredefinedPeriod($event.value)"
      >
      @for (period of predefinedPeriodOptions; track period; let i = $index) {
        <mat-option [value]="i">
          {{ t('dashboard.data.salesChart.dataRange.' + period.label)}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="mr-3 form__field --normal"
    [matTooltip]="t('dashboard.data.salesChart.dataRange.dateIncluded')">
    <mat-label>{{ t('dashboard.data.salesChart.dataRange.enterPeriod') }}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input
        matStartDate
        [formControl]="controlStart"
        [placeholder]="t('dashboard.data.salesChart.dataRange.start')"
        (dateChange)="cleanPeriod()"
        >
      <input
        matEndDate
        [formControl]="controlEnd"
        [max]="maxDate"
        [placeholder]="t('dashboard.data.salesChart.dataRange.end')"
        (dateChange)="cleanPeriod()"
        >
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"/>
    <mat-date-range-picker #picker firstDayOfWeek="1"/>
    <mat-error>{{ t('dashboard.data.salesChart.dataRange.invalidDates') }}</mat-error>
  </mat-form-field>
</div>

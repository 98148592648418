
<div class="press-articles">
  <div
    class="press-articles__carousel"
  >

    <app-carousel-scroll
      [items]="press"
      [itemSize]="itemSize"
    >
     <ng-template let-item="item">
       <app-press-card [press]="item" class="press-articles__carousel__item"/>
     </ng-template>
    </app-carousel-scroll>

  </div>
</div>
